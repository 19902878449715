import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../../helpers/colors';

function Paragraph({ title, content }) {
  const classes = useStyles();

  return (
    <>
      {title && (
      <Typography variant="h5" color={colors.black} align="left" className={classes.title}>
        {title}
      </Typography>
      )}
      <Typography variant="body2" color={colors.black} align="left" className={classes.paragraph}>
        {content}
      </Typography>
    </>
  );
}

Paragraph.defaultProps = {
  title: null,
};
Paragraph.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default Paragraph;
