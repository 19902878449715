import { connect } from 'react-redux';
import ArticlePage from './ArticlePage';
import { fetchArticle, fetchArticles } from '../../store/articles/actions';
import { getArticle, getArticles } from '../../store/articles/selectors';

const mapStateToProps = (state) => ({
  currentArticle: getArticle(state),
  articles: getArticles(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentArticle: (articleId) => dispatch(fetchArticle(articleId)),
  fetchArticles: () => dispatch(fetchArticles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);
