import React, { useEffect, useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './styles';
import unicornlyLogoDark from '../../../img/logo_dark.png';
import colors from '../../../helpers/colors';
import useWindowHeight from '../../../hooks/useWindowHeight';
import globalVariables from '../../../helpers/globalVariables';
import ContactDialog from '../../ContactDialog';
import pages from '../../../helpers/pages';

function DesktopView({
  changeTransparency,
  changeLink,
  changeVisibility,
  linkColor,
  transparency,
  visibility,
}) {
  const windowHeight = useWindowHeight();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allPages, setActivePage] = useState(pages);
  const location = useLocation();

  useEffect(() => {
    setActivePage((prevPages) => prevPages.map((page) => ({
      ...page,
      isActive: page.link === location.pathname,
    })));
  }, [location]);

  const handleDialogState = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  const changeLinkColor = () => {
    if (window.scrollY + globalVariables.linkColorWindowOffsetY <= windowHeight) {
      changeLink(colors.white);
    } else {
      changeLink(colors.black);
    }
  };

  const changeNavTransparency = () => {
    if (window.scrollY <= windowHeight) {
      changeTransparency(true);
    } else {
      changeTransparency(false);
    }
  };

  const changeNavDisplay = () => (window.scrollY
      > document.body.clientHeight - globalVariables.footerHeight
    ? changeVisibility(false) : changeVisibility(true)
  );

  useLayoutEffect(() => {
    changeLinkColor();
    changeNavDisplay();
    changeNavTransparency();

    window.onscroll = () => {
      changeLinkColor();
      changeNavDisplay();
      changeNavTransparency();
    };
  }, [windowHeight, linkColor, transparency, visibility]);

  const classes = useStyles({
    color: linkColor,
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" className={classes.root}>
        <Link to="/" className={classes.logoLink}>
          <img alt="unicornly logo link" src={unicornlyLogoDark} />
        </Link>
        <Box display="flex" alignItems="center" className={classes.linksContainer}>
          {allPages.map((page) => (
            <Link
              to={page.link}
              key={page.id}
              className={page.isActive ? classes.activeLink : classes.link}
            >
              <Typography variant="body2" className={classes.typography}>
                {page.description}
              </Typography>
            </Link>
          ))}
          <Button className={classes.button} onClick={handleDialogState}>
            <Typography variant="body2" color={colors.white} align="center" className={classes.buttonText}>
              Contact Us
            </Typography>
          </Button>
        </Box>
      </Box>
      <Divider className={classes.divider} />

      <ContactDialog dialogState={isDialogOpen} handleDialogState={handleDialogState} />
    </>
  );
}

DesktopView.propTypes = {
  changeVisibility: PropTypes.func.isRequired,
  changeTransparency: PropTypes.func.isRequired,
  changeLink: PropTypes.func.isRequired,
  visibility: PropTypes.bool.isRequired,
  transparency: PropTypes.bool.isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default DesktopView;
