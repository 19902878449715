import { connect } from 'react-redux';
import DesktopView from './DesktopView';
import {
  getLinkColor,
  getTransparency,
  getVisibility,
} from '../../../store/navBar/selectors';
import { changeLinkColor, changeTransparency, changeVisibility } from '../../../store/navBar/actions';

const mapStateToProps = (state) => ({
  visibility: getVisibility(state),
  transparency: getTransparency(state),
  linkColor: getLinkColor(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibility: (value) => { dispatch(changeVisibility(value)); },
  changeTransparency: (value) => { dispatch(changeTransparency(value)); },
  changeLink: (value) => { dispatch(changeLinkColor(value)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopView);
