import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useStyles from './styles';
import colors from '../../../helpers/colors';

function ContactSection() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="body2" color={colors.white} className={classes.title}>
        CONTACT
      </Typography>
      <Typography variant="body2" color={colors.gray} className={classes.address}>
        Unicornly is a part of bPol
        bPolNet sp. z o.o. (HQ)
        Czluchowska 9/6, Warsaw
        01360, Poland
      </Typography>
      <Typography variant="body2" color={colors.violet} className={classes.email}>
        hello@unicornly.io
      </Typography>
    </Box>
  );
}

export default ContactSection;
