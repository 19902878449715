import { makeStyles } from '@mui/styles';
import mobileEllipseOne from '../../../img/mobile_ellipse_1.svg';
import mobileEllipseTwo from '../../../img/mobile_ellipse_2.svg';
import desktopEllipseOne from '../../../img/desktop_ellipse_1.svg';
import desktopEllipseTwo from '../../../img/desktop_ellipse_2.svg';
import desktopEllipseThree from '../../../img/desktop_ellipse_3.svg';
import contactUs from '../../../img/contact_us_btn.svg';
import contactUsDesktop from '../../../img/contact_us_btn_desktop.svg';
import colors from '../../../helpers/colors';

const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
  rectangle: {
    height: '16.2rem',
    marginBottom: '12.7rem',
    backgroundColor: colors.violet,
    backgroundImage: `url(${mobileEllipseOne}), url(${mobileEllipseTwo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  typography: {
    marginTop: '3rem',
  },
  button: {
    marginTop: '3rem',
    marginBottom: '3rem',
    width: '14.2rem',
    height: '4.3rem',
    padding: 0,
    backgroundImage: `url(${contactUs})`,
    boxShadow: '0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0',
  },
  buttonText: {
    fontWeight: '700',
    letterSpacing: '0.1rem',
    textTransform: 'none',
  },
  '@media screen and (min-width: 900px)': {
    rectangle: {
      height: '20rem',
      marginBottom: '20rem',
      backgroundImage: `url(${desktopEllipseOne}), url(${desktopEllipseTwo}), url(${desktopEllipseThree})`,
    },
    typography: {
      fontSize: '4.6rem',
      lineHeight: '5.5rem',
      marginTop: '3.3rem',
    },
    button: {
      marginBottom: '3.3rem',
      width: '22.5rem',
      height: '5rem',
      backgroundImage: `url(${contactUsDesktop})`,
    },
    buttonText: {
      textTransform: 'none',
    },
  },
});

export default useStyles;
