import { createReducer } from '@reduxjs/toolkit';
import initialStatistics from './defaultState';
import { setStatisticsSuccess, setStatisticsError } from './actions';

const statisticsReducer = createReducer(initialStatistics, (builder) => {
  builder
    .addCase(setStatisticsSuccess, (state, action) => {
      state.statistics = action.payload;
    })
    .addCase(setStatisticsError, (state, action) => {
      state.errorMessage = action.payload;
    });
});

export default statisticsReducer;
