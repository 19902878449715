import { combineReducers } from 'redux';
import caseStudiesReducer from './caseStudies/reducer';
import testimonialsReducer from './testimonials/reducer';
import clientsReducer from './clients/reducer';
import navBarReducer from './navBar/reducer';
import statisticsReducer from './statistics/reducer';
import leadershipsReducer from './leaderships/reducer';
import articlesReducer from './articles/reducer';
import sendMailReducer from './mailService/reducer';

const rootReducer = combineReducers({
  leaderships: leadershipsReducer,
  caseStudies: caseStudiesReducer,
  articles: articlesReducer,
  testimonials: testimonialsReducer,
  clients: clientsReducer,
  statistics: statisticsReducer,
  navBar: navBarReducer,
  sendMail: sendMailReducer,
});

export default rootReducer;
