import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useStyles from './styles';
import colors from '../../../../../helpers/colors';

function Categories({ categories }) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" className={classes.container}>
      {categories.length > 0 && categories.map((category) => (
        <Typography variant="subtitle2" color={colors.violet} className={classes.typography} key={category.id}>
          {category.name}
        </Typography>
      ))}
    </Box>
  );
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

export default Categories;
