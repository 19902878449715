import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

function MetaData({
  title,
  metaDescription,
  shareUrl,
  shareDialogImage,
  shareDialogTitle,
  shareDialogDescription,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      {shareUrl && <meta property="url" content={shareUrl} />}
      {shareDialogTitle && <meta property="title" content={shareDialogTitle} />}
      {shareDialogImage && <meta property="image" content={shareDialogImage} />}
      {shareUrl && <meta property="og:url" content={shareUrl} />}
      {shareDialogTitle && <meta property="og:title" content={shareDialogTitle} />}
      {shareDialogDescription && <meta property="og:description" content={shareDialogDescription} />}
      {shareDialogImage && <meta property="og:image" content={shareDialogImage} />}
    </Helmet>
  );
}
MetaData.defaultProps = {
  title: '',
  metaDescription: '',
  shareUrl: '',
  shareDialogTitle: '',
  shareDialogDescription: '',
  shareDialogImage: '',
};
MetaData.propTypes = {
  title: PropTypes.string,
  metaDescription: PropTypes.string,
  shareUrl: PropTypes.string,
  shareDialogTitle: PropTypes.string,
  shareDialogDescription: PropTypes.string,
  shareDialogImage: PropTypes.string,
};

export default MetaData;
