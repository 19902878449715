import { makeStyles } from '@mui/styles';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'none',
    '@media (min-width: 900px)': {
      backgroundImage: `linear-gradient(to right, ${colors.white} 50%, ${colors.violet} 50%)`,
      position: 'relative',
    },
  },
});

export default useStyles;
