import architectureIcon from '../../../img/what-we-provide-architecture-icon.svg';
import codebaseIcon from '../../../img/what-we-provide-codebase-icon.svg';
import infrastructureIcon from '../../../img/what-we-provide-infrastructure-icon.svg';
import securityIcon from '../../../img/what-we-provide-security-icon.svg';
import technicalTeamIcon from '../../../img/what-we-provide-technicalteam-icon.svg';
import processesIcon from '../../../img/what-we-provide-processes-icon.svg';
import ipOpenSourceIcon from '../../../img/what-we-provide-ip-icon.svg';
import productStrategyIcon from '../../../img/what-we-provide-productstrategy-icon.svg';
import marketIcon from '../../../img/what-we-provide-market-icon.svg';
import acquisitionIcon from '../../../img/what-we-provide-acquisition-icon.svg';
import retentionIcon from '../../../img/what-we-provide-retention-icon.svg';
import userExperienceIcon from '../../../img/what-we-provide-userexperience-icon.svg';

const tabsContent = [
  {
    primary: {
      id: 1,
      title: 'Architecture',
      icon: architectureIcon,
      descriptions: [
        { id: 1, value: 'Software architecture' },
        { id: 2, value: 'Technology stack' },
        { id: 3, value: 'Dependencies on external data' },
        { id: 4, value: 'Scalability and extensibility' },
      ],
    },
    secondary: {
      id: 2,
      title: 'Codebase',
      icon: codebaseIcon,
      descriptions: [
        { id: 1, value: 'Source code quality' },
        { id: 2, value: 'Technical debt' },
        { id: 3, value: 'Maintainability' },
        { id: 4, value: 'Ease of replication' },
      ],
    },
  },
  {
    primary: {
      id: 3,
      title: 'Infrastructure',
      icon: infrastructureIcon,
      descriptions: [
        { id: 1, value: 'System architecture' },
        { id: 2, value: 'DevOps' },
        { id: 3, value: 'Performance monitoring' },
        { id: 4, value: 'System scalability' },
      ],
    },
    secondary: {
      id: 4,
      title: 'Security',
      icon: securityIcon,
      descriptions: [
        { id: 1, value: 'Best practices' },
        { id: 2, value: 'Disaster recovery' },
        { id: 3, value: 'Security monitoring' },
        { id: 4, value: 'Known vulnerabilities' },
      ],
    },
  },
  {
    primary: {
      id: 5,
      title: 'Technical Team',
      icon: technicalTeamIcon,
      descriptions: [
        { id: 1, value: 'Key personnel' },
        { id: 2, value: 'Team competence and organization' },
        { id: 3, value: 'Talent acquisition and retention' },
        { id: 4, value: 'R&D spend' },
      ],
    },
    secondary: {
      id: 6,
      title: 'Processes',
      icon: processesIcon,
      descriptions: [
        { id: 1, value: 'Software development process' },
        { id: 2, value: 'Methodologies' },
        { id: 3, value: 'Tools' },
        { id: 4, value: 'Scalability' },
      ],
    },
  },
  {
    primary: {
      id: 7,
      title: 'IP & Open Source',
      icon: ipOpenSourceIcon,
      descriptions: [
        { id: 1, value: 'Discovery of open-source components' },
        { id: 2, value: 'License violations' },
        { id: 3, value: 'Security vulnerabilities' },
        { id: 4, value: 'IP ownership' },
      ],
    },
    secondary: {
      id: 8,
      title: 'Product Strategy',
      icon: productStrategyIcon,
      descriptions: [
        { id: 1, value: 'Product development process' },
        { id: 2, value: 'Vision, strategy and roadmap' },
        { id: 3, value: 'SWOT analysis' },
        { id: 4, value: 'Roadmap feasibility' },
      ],
    },
  },
  {
    primary: {
      id: 9,
      title: 'Market',
      icon: marketIcon,
      descriptions: [
        { id: 1, value: 'Market size' },
        { id: 2, value: 'Emerging trends and potential disruptors' },
        { id: 3, value: 'Competition' },
        { id: 4, value: 'Market positioning' },
      ],
    },
    secondary: {
      id: 10,
      title: 'Acquisition',
      icon: acquisitionIcon,
      descriptions: [
        { id: 1, value: 'Digital strategy' },
        { id: 2, value: 'Acquisition channels' },
        { id: 3, value: 'Conversion' },
        { id: 4, value: 'Lead nurturing' },
      ],
    },
  },
  {
    primary: {
      id: 11,
      title: 'Retention',
      icon: retentionIcon,
      descriptions: [
        { id: 1, value: 'Onboarding and usage' },
        { id: 2, value: 'Upselling and cross-selling' },
        { id: 3, value: 'Churn' },
        { id: 4, value: 'Brand sentiment' },
      ],
    },
    secondary: {
      id: 12,
      title: 'User Experience',
      icon: userExperienceIcon,
      descriptions: [
        { id: 1, value: 'Customer experience across all touchpoints' },
        { id: 2, value: 'Product quality' },
        { id: 3, value: 'Usability' },
        { id: 4, value: 'Customer support' },
      ],
    },
  },
];

export default tabsContent;
