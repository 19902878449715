import { makeStyles } from '@mui/styles';
import unicornlyNumbers from '../../../img/unicornly_numbers_bg.png';

const useStyles = makeStyles({
  title: {
    margin: '11.8rem 0 3rem 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '19.8rem 0 2.1rem 10rem',
    },
  },
  years: {
    '@media screen and (min-width: 900px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
  },
  yearsContainer: {
    backgroundImage: `url(${unicornlyNumbers})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '16.5rem',
    height: '16.5rem',
    '@media screen and (min-width: 325px)': {
      width: '18rem',
      height: '18rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '30rem',
      height: '30rem',
      transform: 'translateX(6.5rem)',
    },
    '@media screen and (min-width: 1400px)': {
      width: '47rem',
      height: '47rem',
      transform: 'translateX(8.5rem)',
    },
  },
  yearsTextContainer: {
    width: '11rem',
    height: '18rem',
    '@media screen and (min-width: 900px)': {
      justifyContent: 'center',
      width: '30rem',
      height: '3.1rem',
      transform: 'translateY(-14rem) translateX(6.5rem)',
    },
    '@media screen and (min-width: 1400px)': {
      width: '47rem',
      transform: 'translateY(-22rem) translateX(8.5rem)',
    },
  },
  projects: {
    '@media screen and (min-width: 900px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxWidth: '30rem',
    },
    '@media screen and (min-width: 1400px)': {
      maxWidth: '47rem',
    },
  },
  projectsContainer: {
    backgroundImage: `url(${unicornlyNumbers})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '16.5rem',
    height: '16.5rem',
    transform: 'translateY(-4.3rem)',
    '@media screen and (min-width: 325px)': {
      width: '18rem',
      height: '18rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '30rem',
      height: '30rem',
      transform: 'translateY(0)',
    },
    '@media screen and (min-width: 1400px)': {
      width: '47rem',
      height: '47rem',
    },
  },
  projectsTextContainer: {
    width: '11rem',
    height: '18rem',
    transform: 'translateY(-4.3rem)',
    '@media screen and (min-width: 900px)': {
      justifyContent: 'center',
      width: '30rem',
      height: '3.1rem',
      transform: 'translateY(-14rem)',
    },
    '@media screen and (min-width: 1400px)': {
      width: '47rem',
      transform: 'translateY(-22rem)',
    },
  },
  deals: {
    '@media screen and (min-width: 900px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  dealContainer: {
    backgroundImage: `url(${unicornlyNumbers})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '16.5rem',
    height: '16.5rem',
    transform: 'translateY(-8.6rem)',
    '@media screen and (min-width: 325px)': {
      width: '18rem',
      height: '18rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '30rem',
      height: '30rem',
      transform: 'translateY(0) translateX(-6.5rem)',
    },
    '@media screen and (min-width: 1400px)': {
      width: '47rem',
      height: '47rem',
      transform: 'translateX(-8.5rem)',
    },
  },
  dealTextContainer: {
    width: '11rem',
    height: '18rem',
    transform: 'translateY(-8.6rem)',
    '@media screen and (min-width: 900px)': {
      justifyContent: 'center',
      width: '30rem',
      height: '3.1rem',
      transform: 'translateY(-14rem) translateX(-6.5rem)',
    },
    '@media screen and (min-width: 1400px)': {
      width: '47rem',
      transform: 'translateY(-22rem) translateX(-8.5rem)',
    },
  },
  valueText: {
    fontStyle: 'italic',
    '@media screen and (min-width: 900px)': {
      transform: 'translateY(-2rem)',
      fontSize: '4.8rem',
    },
  },
  descriptionText: {
    fontStyle: 'italic',
    marginLeft: '2.7rem',
    '@media screen and (min-width: 900px)': {
      marginLeft: '0',
    },
  },
});

export default useStyles;
