import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useStyles from './styles';
import Footer from '../../Footer';
import colors from '../../../helpers/colors';
import ContactDialog from '../../ContactDialog';

function LetsTalkToday() {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogState = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="column" className={classes.rectangle}>
        <Typography variant="h5" color={colors.white} align="center" className={classes.typography}>
          Let’s talk today
        </Typography>
        <Button className={classes.button} onClick={handleDialogState}>
          <Typography variant="body1" color={colors.violet} align="center" className={classes.buttonText}>
            Contact Us
          </Typography>
        </Button>
      </Box>
      <Footer />
      <ContactDialog dialogState={isDialogOpen} handleDialogState={handleDialogState} />
    </Box>
  );
}

export default LetsTalkToday;
