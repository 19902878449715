import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useStyles from './styles';
import tabsContent from '../tabsContent';
import colors from '../../../../helpers/colors';

const TabsMobile = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();

  const handlePrev = () => {
    if (tabIndex > 0) {
      setTabIndex((prevIndex) => prevIndex - 1);
    } else {
      setTabIndex(tabsContent.length - 1);
    }
  };

  const handleNext = () => {
    if (tabIndex < tabsContent.length - 1) {
      setTabIndex((prevIndex) => prevIndex + 1);
    } else {
      setTabIndex(0);
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          container
          className={classes.rectangle}
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Grid xs={12} item display="flex">
            <img src={tabsContent[tabIndex].primary.icon} alt="tab icon" />
            <Typography variant="h5" color={colors.black} className={classes.typographyTitle}>
              {tabsContent[tabIndex].primary.title}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.descriptionContainer}>
            <Typography
              component="span"
              variant="body2"
              color={colors.black}
              align="left"
              className={classes.description}
            >
              <ul>
                {tabsContent[tabIndex].primary.descriptions.map((description) => (
                  <li key={description.id}>{description.value}</li>
                ))}
              </ul>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.rectangle}
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Grid item xs={12} display="flex">
            <img src={tabsContent[tabIndex].secondary.icon} alt="tab icon" />
            <Typography variant="h5" color={colors.black} className={classes.typographyTitle}>
              {tabsContent[tabIndex].secondary.title}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.descriptionContainer}>
            <Typography
              component="span"
              variant="body2"
              color={colors.black}
              align="left"
              className={classes.description}
            >
              <ul>
                {tabsContent[tabIndex].secondary.descriptions.map((description) => (
                  <li key={description.id}>{description.value}</li>
                ))}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className={classes.buttonContainer}
        display="flex"
        justifyContent="space-between"
      >
        <NavigateBeforeIcon onClick={handlePrev} />
        <NavigateNextIcon onClick={handleNext} />
      </Grid>
    </>
  );
};

export default TabsMobile;
