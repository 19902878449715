import { makeStyles } from '@mui/styles';
import colors from '../../../helpers/colors';

const useStyles = makeStyles(() => ({
  typography: {
    textTransform: 'uppercase',
    margin: '9.3rem 0 0 2rem',
    '@media (min-width: 900px)': {
      margin: '20rem 0 0 10rem',
    },
  },
  description: {
    fontStyle: 'italic',
    margin: '4rem 2rem 4rem 3rem',
    '@media (min-width: 900px)': {
      lineHeight: '3.1rem',
      margin: '4rem 2rem 4rem 11rem',
    },
  },
  typographyLarge: {
    margin: '2rem 2rem 5rem 3rem',
    lineHeight: '4rem',
    '@media (min-width: 900px)': {
      margin: '4rem 2rem 4rem 11rem',
      lineHeight: '5.8rem',
    },
  },
  stepDescription: {
    fontStyle: 'italic',
    margin: '-3.2rem 5rem 0 2rem',
  },
  stepperContainer: {
    '@media (min-width: 900px)': {
      margin: '20rem 0 0 0',
    },
  },
  stepper: {
    marginLeft: '2rem',
    '&:nth-child(1)': {
      '& span': {
        borderColor: colors.whiteSmoke,
      },
    },
    '& svg': {
      fontSize: '3rem',
      color: `${colors.whiteSmoke} !important`,
      '& text': {
        display: 'none',
      },
    },
    '@media (min-width: 900px)': {
      margin: '0 5rem 0 2rem',
    },
  },
  stepContentContainer: {
    borderColor: colors.whiteSmoke,
    textAlign: 'center',
  },
}));

export default useStyles;
