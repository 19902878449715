import { createReducer } from '@reduxjs/toolkit';
import initialCaseStudies from './defaultState';
import {
  setCaseStudiesSuccess, setCaseStudiesError, setCaseStudySuccess,
} from './actions';

const caseStudiesReducer = createReducer(initialCaseStudies, (builder) => {
  builder
    .addCase(setCaseStudySuccess, (state, action) => {
      state.caseStudy = action.payload;
    })
    .addCase(setCaseStudiesSuccess, (state, action) => {
      state.caseStudies = action.payload;
    })
    .addCase(setCaseStudiesError, (state, action) => {
      state.errorMessage = action.payload;
    });
});

export default caseStudiesReducer;
