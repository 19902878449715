import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { Link } from '@mui/material';
import { ReactComponent as LinkedInIcon } from '../../../img/linkedin_icon.svg';
import useStyles from './styles';
import colors from '../../../helpers/colors';
import ContactDialog from '../../ContactDialog';

function LetsTalkSection() {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogState = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="body2" color={colors.white} className={classes.title}>
        LET’S TALK TODAY
      </Typography>
      <Typography variant="body2" color={colors.white} className={classes.content}>
        Contact us for a meeting or a quick call to explore
        how we could help with your investment case
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.buttonContainer}>
        <Button className={classes.contactUsBtn} onClick={handleDialogState}>
          <Typography variant="h5" color={colors.white} align="center" className={classes.contactUsBtnText}>
            Contact Us
          </Typography>
        </Button>
        <Link display="flex" alignItems="center" className={classes.catchUsLink} href="https://www.linkedin.com/company/unicornly-io/" target="_blank">
          <SvgIcon component={LinkedInIcon} fontSize="large" viewBox="0 0 30 30" className={classes.catchUsIcon} titleAccess="linkedIn-icon" />
          <Typography variant="h5" textAlign="center" color={colors.violet} className={classes.catchUsLinkText}>
            Catch us on LinkedIn
          </Typography>
        </Link>
      </Box>
      <ContactDialog dialogState={isDialogOpen} handleDialogState={handleDialogState} />
    </Box>
  );
}

export default LetsTalkSection;
