import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  logo: {
    width: '20.4rem',
    height: '4.8rem',
    display: 'block',
    position: 'absolute',
    top: '50%',
    right: '25%',
    marginTop: '-2.4rem',
    marginRight: '-10.2rem',
  },
});

export default useStyles;
