import { createReducer } from '@reduxjs/toolkit';
import initialNavBar from './defaultState';
import {
  changeLinkColor, changeTheme, changeTransparency, changeVisibility,
} from './actions';

const navBarReducer = createReducer(initialNavBar, (builder) => {
  builder
    .addCase(changeVisibility, (state, action) => {
      state.visibility = action.payload;
    })
    .addCase(changeTransparency, (state, action) => {
      state.transparency = action.payload;
    })
    .addCase(changeLinkColor, (state, action) => {
      state.linkColor = action.payload;
    })
    .addCase(changeTheme, (state, action) => {
      state.theme = action.payload;
    });
});

export default navBarReducer;
