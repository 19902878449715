import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../helpers/colors';
import LeaderCard from './LeaderCard';

function LeadershipSection({ fetchLeaderships, leaderships }) {
  const classes = useStyles();

  useEffect(() => {
    if (leaderships.length === 0) {
      fetchLeaderships();
    }
  }, []);

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Typography variant="h2" color={colors.black} align="left" className={classes.title}>
          THE LEADERSHIP TEAM
        </Typography>
      </Grid>
      <Grid item display="flex" justifyContent="center">
        <Grid container direction="row" columnSpacing={3} maxWidth="125rem">
          {leaderships.map((leader) => (
            <Grid key={leader.id} item xs={12} lg={4} display="flex" justifyContent="center">
              <LeaderCard leader={leader} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

LeadershipSection.propTypes = {
  fetchLeaderships: PropTypes.func.isRequired,
  leaderships: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  })).isRequired,
};

export default LeadershipSection;
