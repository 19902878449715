import { connect } from 'react-redux';
import NotFound from './NotFoundPage';
import { getLinkColor, getTheme } from '../../store/navBar/selectors';
import { changeLinkColor, changeTheme } from '../../store/navBar/actions';

const mapStateToProps = (state) => ({
  linkColor: getLinkColor(state),
  navBarTheme: getTheme(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLink: (value) => { dispatch(changeLinkColor(value)); },
  changeTheme: (value) => { dispatch(changeTheme(value)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
