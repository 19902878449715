import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    '@media (min-width: 900px)': {
      alignItems: 'center',
    },
  },
  intro: {
    margin: '4rem 2rem 0 2rem',
    '@media (min-width: 900px)': {
      maxWidth: '50rem',
    },
  },
});

export default useStyles;
