import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchCaseStudies,
  fetchCaseStudy,
  setCaseStudiesSuccess,
  setCaseStudiesError,
  setCaseStudySuccess,
} from './actions';
import getContent from '../../services/getContent';
import { CASE_STUDY_URL } from '../../services/apiConfig';

function* fetchCaseStudiesSaga() {
  try {
    const result = yield call(getContent, CASE_STUDY_URL);
    yield put(setCaseStudiesSuccess(result));
  } catch (error) {
    yield put(setCaseStudiesError(`Case Studies - data fetching failed: ${error}`));
  }
}

function* fetchCaseStudySaga(action) {
  try {
    const result = yield call(getContent, `${CASE_STUDY_URL}/${action.payload.caseStudyId.id}`);
    yield put(setCaseStudySuccess(result));
  } catch (error) {
    yield put(setCaseStudiesError(`Case Study - data fetching failed: ${error}`));
  }
}

export function* watchFetchCaseStudiesSaga() {
  yield takeEvery(String(fetchCaseStudies), fetchCaseStudiesSaga);
}

export function* watchFetchCaseStudySaga() {
  yield takeEvery(String(fetchCaseStudy), fetchCaseStudySaga);
}
