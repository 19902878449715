import React from 'react';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import OurApproach from './OurApproach';
import DiligenceReport from './DiligenceReport';
import LetsTalkToday from '../HomePage/LetsTalkToday';
import HowWeDoIt from './HowWeDoIt';
import WhatWeProvide from './WhatWeProvide';
import MetaData from '../MetaData';
import Intro from '../Intro';
import pageContent from '../Intro/PagesIntroContent';
import backgroundImage from '../../img/what-we-do-background.png';
import useStyles from '../Intro/styles';

function WhatWeDoPage() {
  const classes = useStyles();
  return (
    <>
      <MetaData
        metaDescription="12 categories of digital analysis, split between tech and marketing, allow Unicornly teams to illustrate digital maturity of an asset and raise potential issues as red flags."
        title="Types of Due Diligence Offered | Unicornly"
      />
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid item>
            <Intro
              title={pageContent.WhatWeDoPage.pageTitle}
              description={pageContent.WhatWeDoPage.description}
              backgroundImage={backgroundImage}
              backgroundPosition="20% 30%"
              descriptionStyle={classes.mainDescription}
            />
          </Grid>
          <OurApproach />
          <DiligenceReport />
          <HowWeDoIt />
          <WhatWeProvide />
          <LetsTalkToday />
        </Grid>
      </Fade>
    </>
  );
}

export default WhatWeDoPage;
