import { makeStyles } from '@mui/styles';
import card from '../../../../img/card.svg';
import cardDesktop from '../../../../img/card_desktop.svg';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  card: {
    backgroundImage: `url(${card})`,
    width: '29.5rem',
    height: '32.3rem',
    boxSizing: 'border-box',
    marginTop: '11.2rem',
    marginBottom: '12rem',
    '@media screen and (min-width: 900px)': {
      backgroundImage: `url(${cardDesktop})`,
      backgroundSize: '100%',
      width: '62.3rem',
      height: '41.6rem',
      marginTop: '24rem',
      marginBottom: 0,
    },
  },
  container: {
    '& svg': {
      fontSize: '3rem',
    },
  },
  button: {
    color: colors.gray,
    margin: '2rem 1rem 0 1rem',
    cursor: 'pointer',
    '@media screen and (min-width: 900px)': {
      margin: '6.5rem 1rem 0 1rem',
    },
  },
  image: {
    width: '15rem',
    height: '3rem',
    marginTop: '2rem',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    '@media screen and (min-width: 900px)': {
      marginTop: '5rem',
      width: '30rem',
      height: '6rem',
    },
  },
  typography: {
    height: '15.5rem',
    margin: '3rem 2rem 0 2rem',
    overflow: 'hidden',
    '@media screen and (min-width: 900px)': {
      height: '15.5rem',
      width: '30.5rem',
      margin: '4rem 0 0 16rem',
    },
  },
});

export default useStyles;
