import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    background: 'transparent',
    margin: '2rem 0 1.5rem 2rem',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: ({ display, dividerColor }) => ({
    display,
    borderColor: dividerColor,
    borderStyle: '0.5rem, solid',
    margin: '0 2rem',
  }),
  logoLink: {
    border: 'none',
    background: 'transparent',
    padding: 0,
    '& img': {
      width: '16rem',
    },
  },
  button: ({ buttonColor }) => ({
    padding: 0,
    color: buttonColor,
    '& svg': {
      fontSize: '3rem',
    },
  }),
});

export default useStyles;
