import { makeStyles } from '@mui/styles';
import colors from '../../../helpers/colors';

const useStyles = makeStyles({
  typography: {
    margin: '9.3rem 0 0 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 0 10rem',
    },
  },
  container: {
    '@media screen and (min-width: 900px)': {
      flexDirection: 'row',
      marginBottom: '11.3rem',
    },
  },
  intro: {
    margin: '4rem 2.4rem 0 2rem',
    '& span': {
      color: colors.violet,
    },
    '@media screen and (min-width: 900px)': {
      margin: '5rem 3.8rem 0 10rem',
      width: '50%',
    },
  },
  description: {
    margin: '2rem 2rem 4rem 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '5rem 10rem 1rem 3.8rem',
      width: '50%',
      alignSelf: 'flex-end',
    },
  },
  caseStudyContainer: {
    '@media screen and (min-width: 900px)': {
      margin: '0 10rem 16rem 10rem',
      justifyContent: 'center',
      columnGap: '2rem',
    },
    '@media screen and (min-width: 1255px)': {
      flexDirection: 'row',
    },
  },
});

export default useStyles;
