import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../../helpers/colors';

function Quote({ quote }) {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      className={classes.quoteContainer}
    >
      <Typography variant="body2" color={colors.black} align="left" className={classes.quote}>
        {quote}
      </Typography>
    </Grid>
  );
}

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
};

export default Quote;
