import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../../helpers/colors';
import Form from './Form';

function FormToFill({ handleDialogState, setSubmitted }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Typography variant="h5" align="left" color={colors.black} className={classes.title}>
            How can we help you?
          </Typography>
          <button
            type="button"
            className={classes.exitButton}
            onClick={handleDialogState}
          >
            <CloseIcon />
          </button>
        </Grid>
        <Typography variant="body2" align="left" color={colors.black} className={classes.descriptionText}>
          Contact us for a meeting or a quick call to explore
          how we could help with your investment cases
        </Typography>
        <Typography variant="subtitle1" align="left" color={colors.darkGray} className={classes.mandatoryText}>
          *mandatory fields
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Form setSubmitted={setSubmitted} />
      </Grid>
    </>
  );
}

FormToFill.propTypes = {
  handleDialogState: PropTypes.func.isRequired,
  setSubmitted: PropTypes.func.isRequired,
};

export default FormToFill;
