import { makeStyles } from '@mui/styles';
import contactUs from '../../../../img/lets_talk_btn_violet.svg';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    width: '100%',
  },
  container: {
    margin: '13.6rem 2rem 0 0',
  },
  typography: {
    marginBottom: '3rem',
  },
  link: {
    textDecoration: 'none',
  },
  activeLink: {
    textDecoration: 'underline',
    color: colors.violet,
  },
  contactButton: {
    position: 'fixed',
    bottom: '1.7rem',
    right: '2rem',
    width: '33.5rem',
    height: '7rem',
    cursor: 'pointer',
    background: `url(${contactUs})`,
    boxShadow: '0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
    borderRadius: 0,
    padding: 0,
    textTransform: 'none',
    '@media screen and (max-width: 320px)': {
      width: '28rem',
    },
  },
});

export default useStyles;
