const pages = [
  {
    id: 1,
    description: 'What We Do',
    link: '/what-we-do',
    isActive: false,
  },
  {
    id: 2,
    description: 'Our Experience',
    link: '/experience',
    isActive: false,
  },
  {
    id: 3,
    description: 'About Us',
    link: '/about',
    isActive: false,
  },
  {
    id: 4,
    description: 'Blog',
    link: '/blog',
    isActive: false,
  },
];

export default pages;
