import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import useStyles from './styles';
import Categories from './Categories';
import colors from '../../../../helpers/colors';

function Card({ fetchClients, clients }) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (clients.length === 0) {
      fetchClients();
    }
  }, []);

  const handlePrev = () => {
    if (tabIndex > 0) {
      setTabIndex((prevIndex) => prevIndex - 1);
    } else {
      setTabIndex(clients.length - 1);
    }
  };

  const handleNext = () => {
    if (tabIndex < clients.length - 1) {
      setTabIndex((prevIndex) => prevIndex + 1);
    } else {
      setTabIndex(0);
    }
  };

  return (
    <Box className={classes.card}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.container}>
        <NavigateBeforeIcon className={classes.button} onClick={handlePrev} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.image}>
          <img alt="title_logo" src={clients.length > 0 && clients[tabIndex].image !== null ? clients[tabIndex].image.url : ''} />
        </Box>
        <NavigateNextIcon className={classes.button} onClick={handleNext} />
      </Box>
      <Typography variant="body2" color={colors.black} className={classes.typography}>
        {clients.length > 0
        && clients[tabIndex].description}
      </Typography>
      <Categories categories={clients.length > 0 ? clients[tabIndex].services : []} />
    </Box>
  );
}

Card.propTypes = {
  fetchClients: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  })).isRequired,
};

export default Card;
