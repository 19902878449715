import { connect } from 'react-redux';
import Testimonials from './Testimonials';
import { fetchTestimonials } from '../../../store/testimonials/actions';
import { getTestimonials, getErrorMessage } from '../../../store/testimonials/selectors';

const mapStateToProps = (state) => ({
  testimonials: getTestimonials(state),
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchTestimonials: () => { dispatch(fetchTestimonials()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
