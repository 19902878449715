import { connect } from 'react-redux';
import NavBar from './NavBar';
import { getTransparency, getVisibility } from '../../store/navBar/selectors';

const mapStateToProps = (state) => ({
  visibility: getVisibility(state),
  transparency: getTransparency(state),
});

export default connect(mapStateToProps)(NavBar);
