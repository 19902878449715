import React, { useState, useLayoutEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './styles';
import MobileViewDrawer from './MobileViewDrawer';
import useWindowHeight from '../../../hooks/useWindowHeight';
import globalVariables from '../../../helpers/globalVariables';
import navBarThemes from '../../../helpers/navBarThemes';

function MobileView({
  changeTransparency,
  changeVisibility,
  changeTheme,
  navBarTheme,
}) {
  const windowHeight = useWindowHeight();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const changeNavColor = () => {
    if (window.scrollY > windowHeight) {
      changeTheme(navBarThemes.dark);
      changeTransparency(false);
    } else {
      changeTheme(navBarThemes.light);
      changeTransparency(true);
    }
  };

  const changeDrawerNavColor = () => (isDrawerOpen
    ? changeTheme(navBarThemes.dark)
    : changeNavColor()
  );

  const changeNavDisplay = () => (window.scrollY
      > document.body.clientHeight - globalVariables.footerHeight
    ? changeVisibility(false) : changeVisibility(true)
  );

  useLayoutEffect(() => {
    changeNavColor();
    changeNavDisplay();

    window.onscroll = () => {
      changeNavColor();
      changeNavDisplay();
    };
  }, [windowHeight]);

  useLayoutEffect(() => {
    changeDrawerNavColor();
  }, [isDrawerOpen]);

  const toggleDrawerVisibility = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    if (isDrawerOpen) toggleDrawerVisibility();
  };

  const classes = useStyles({
    dividerColor: navBarTheme.divider,
    buttonColor: navBarTheme.button,
  });

  return (
    <>
      <Paper className={classes.root}>
        <Link to="/" className={classes.logoLink} onClick={handleLinkClick}>
          <img alt="unicornly logo" src={navBarTheme.logo} />
        </Link>
        <Button className={classes.button} onClick={toggleDrawerVisibility}>
          { isDrawerOpen ? <CloseIcon /> : <MenuIcon /> }
        </Button>
      </Paper>
      <Divider className={classes.divider} />

      <MobileViewDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawerVisibility={toggleDrawerVisibility}
      />
    </>
  );
}

MobileView.propTypes = {
  changeVisibility: PropTypes.func.isRequired,
  changeTransparency: PropTypes.func.isRequired,
  changeTheme: PropTypes.func.isRequired,
  navBarTheme: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MobileView;
