const colors = {
  white: '#FFFFFF',
  whiteSmoke: '#F2F2F2',
  violet: '#6C4EDE',
  lightViolet: '#7454F9',
  darkViolet: '#6440F8',
  darkBlue: '#020115',
  lightGray: '#E5E5E5',
  darkGray: '#767575',
  gray: '#C4C4C4',
  black: '#000000',
  red: '#d32f2f',
};

export default colors;
