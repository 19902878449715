import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import colors from '../../../helpers/colors';
import useStyles from './styles';
import triangleContent from './tringleContent';
import DotIcon from './DotIcon/DotIcon';
import unicornlyLogoDark from '../../../img/logo_dark.png';

function WhyUsSection() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [dots, setDots] = useState([
    {
      id: 0,
      isActive: true,
      dotStyle: `${classes.leftDot}`,
      dotTextStyle: `${classes.leftDotText}`,
      text: 'Maximum potential',
    },
    {
      id: 1,
      isActive: false,
      dotStyle: `${classes.rightDot}`,
      dotTextStyle: `${classes.rightDotText}`,
      text: 'High standards',
    },
    {
      id: 2,
      isActive: false,
      dotStyle: `${classes.bottomDot}`,
      dotTextStyle: `${classes.bottomDotText}`,
      text: 'Transparent cooperation',
    },
  ]);

  const setDotsInactive = () => {
    setDots((prevDots) => {
      const currentDots = [...prevDots];
      Object.keys(currentDots).forEach((dot) => { currentDots[dot].isActive = false; });
      return currentDots;
    });
  };

  const setCurrentDotActive = () => {
    setDots((prevDots) => {
      const currentDots = [...prevDots];
      Object.keys(currentDots).forEach((dot) => {
        if (currentDots[dot].id === tabIndex) {
          currentDots[dot].isActive = true;
        }
      });
      return currentDots;
    });
  };

  useEffect(() => {
    setDotsInactive();
    setCurrentDotActive();
  }, [tabIndex]);

  const handlePrevButtonClick = () => {
    if (tabIndex > 0) {
      setTabIndex((prevIndex) => prevIndex - 1);
    } else {
      setTabIndex(triangleContent.length - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (tabIndex < triangleContent.length - 1) {
      setTabIndex((prevIndex) => prevIndex + 1);
    } else {
      setTabIndex(0);
    }
  };

  return (
    <Grid container flexDirection="row" justifyContent="center">
      <Grid item md={6}>
        <Typography variant="h2" color={colors.black} align="left" className={classes.title}>
          WHY US?
        </Typography>
        <Typography variant="body2" color={colors.black} align="left" className={classes.intro}>
          Effective analysis means wise decisions.  We are focused on supporting corporate
          investors, private equity and venture capital in tech due diligence of potential
          investment opportunities. We help startups, online businesses, stores and online
          platforms by auditing technology, verifying digital solutions and proposing
          improvements that increase ROI for investors. We are happy to share our knowledge
          and experience wherever a thorough analysis of software and technological
          innovations is needed.
        </Typography>
      </Grid>
      <Grid item md={6} display="flex" justifyContent="flex-start" className={classes.container}>
        <Box className={classes.triangleContainer}>
          <Box className={classes.triangle} />
          {dots.map((dot) => (
            <DotIcon
              key={dot.id}
              isActive={dot.isActive}
              dotStyle={dot.dotStyle}
              dotTextStyle={dot.dotTextStyle}
              text={dot.text}
            />
          ))}
          <img alt="unicornly logo" src={unicornlyLogoDark} className={classes.logo} />
          <Typography variant="h5" color={colors.black} align="center" className={classes.contentTitle}>
            {triangleContent[tabIndex].title}
          </Typography>
          <Typography variant="body2" color={colors.black} align="center" className={classes.contentText}>
            {triangleContent[tabIndex].content}
          </Typography>
          <NavigateBeforeIcon onClick={handlePrevButtonClick} className={classes.beforeButton} />
          <NavigateNextIcon onClick={handleNextButtonClick} className={classes.nextButton} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default WhyUsSection;
