import React from 'react';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import LetsTalkToday from '../HomePage/LetsTalkToday';
import WhyUsSection from './WhyUsSection';
import Testimonials from '../HomePage/Testimonials';
import LeadershipSection from './LeadershipSection';
import MetaData from '../MetaData';
import backgroundImage from '../../img/background_about_us_desktop.png';
import Intro from '../Intro';
import pageContent from '../Intro/PagesIntroContent';
import useStyles from './styles';

function AboutPage() {
  const classes = useStyles();

  return (
    <>
      <MetaData
        metaDescription="Unique mix of experience, talent and expertise supports delivery of services highly valued by private equity and venture capital funds."
        title="Digital experience | Unicornly"
      />
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid item>
            <Intro
              title={pageContent.AboutUsPage.pageTitle}
              description={pageContent.AboutUsPage.description}
              backgroundImage={backgroundImage}
              backgroundPosition="20% 30%"
            />
          </Grid>
          <Grid item>
            <WhyUsSection />
          </Grid>
          <Grid item className={classes.testimonialsContainer}>
            <Testimonials />
          </Grid>
          <Grid item>
            <LeadershipSection />
          </Grid>
          <Grid item>
            <LetsTalkToday />
          </Grid>
        </Grid>
      </Fade>
    </>

  );
}

export default AboutPage;
