import React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import WeWorkWithTheBest from './WeWorkWithTheBest';
import WhatWeHaveDone from './WhatWeHaveDone';
import Testimonials from './Testimonials';
import LetsTalkToday from './LetsTalkToday';
import MetaData from '../MetaData';
import Intro from '../Intro';
import pageContent from '../Intro/PagesIntroContent';
import backgroundImage from '../../img/background.png';
import useStyles from '../Intro/styles';

function HomePage() {
  const classes = useStyles();
  return (
    <>
      <MetaData
        metaDescription="Unicornly is a leading vendor of digital and tech due diligence for private equity and venture capital funds."
        title="Digital Due Diligence Provider | Unicornly"
      />
      <Fade in timeout={2000}>
        <Box>
          <Intro
            title={pageContent.Homepage.pageTitle}
            description={pageContent.Homepage.description}
            backgroundImage={backgroundImage}
            titleStyle={classes.homepageTitle}
            descriptionStyle={classes.mainDescription}
          />
          <WeWorkWithTheBest />
          <WhatWeHaveDone />
          <Testimonials />
          <LetsTalkToday />
        </Box>
      </Fade>
    </>
  );
}

export default HomePage;
