import { makeStyles } from '@mui/styles';
import colors from '../../../../helpers/colors';
import quoteBackground from '../../../../img/testimonials_bg.svg';

const useStyles = makeStyles({
  quoteContainer: {
    padding: '3rem',
    marginTop: '4rem',
    backgroundColor: colors.lightGray,
    width: '100%',
    '@media (min-width: 900px)': {
      padding: '8rem',
      minHeight: '38rem',
      backgroundImage: `url(${quoteBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(100% - 15rem) 50%',
      backgroundSize: '39rem 39rem',
    },
  },
  quote: {
    fontStyle: 'italic',
    whiteSpace: 'pre-line',
    '@media (min-width: 900px)': {
      maxWidth: '50vw',
    },
  },
});

export default useStyles;
