import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import colors from '../../../helpers/colors';
import ArticleReferenceCard from '../../BlogPage/OurKnowledgeSection/ArticleReferenceCard';
import useStyles from './styles';

const RemainingArticles = ({ visibleArticles }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item display="flex" flexDirection="column" justifyContent="center">
        <Grid item>
          <Typography variant="h4" colors={colors.black} align="left" className={classes.title}>
            OTHER ARTICLES
          </Typography>
        </Grid>
        <Grid item display="flex" justifyContent="center">
          <Grid container direction="row" justifyContent="center" className={classes.referenceCardContainer}>
            {visibleArticles.map((article) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={article.id}
              >
                <ArticleReferenceCard
                  id={article.id}
                  image={article.image ? article.image.url : ''}
                  title={article.title}
                  referenceLink={`/article/${article.meta_title.replaceAll(' ', '-')}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

RemainingArticles.propTypes = {
  visibleArticles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    meta_title: PropTypes.string,
  })).isRequired,
};

export default RemainingArticles;
