import { connect } from 'react-redux';
import MobileView from './MobileView';
import { getTheme } from '../../../store/navBar/selectors';
import { changeTransparency, changeVisibility, changeTheme } from '../../../store/navBar/actions';

const mapStateToProps = (state) => ({
  navBarTheme: getTheme(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibility: (value) => { dispatch(changeVisibility(value)); },
  changeTransparency: (value) => { dispatch(changeTransparency(value)); },
  changeTheme: (value) => { dispatch(changeTheme(value)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileView);
