import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import useStyles from './styles';
import linkedinIcon from '../../../../img/linkedin_icon_2.svg';
import colors from '../../../../helpers/colors';

function LeaderCard({ leader }) {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.cardContainer}>
      <Box position="relative">
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.image}>
          <img alt="leader" src={leader.photo !== null ? leader.photo.url : ''} />
        </Box>
        <Link className={classes.link} href={leader.linkedin_url} target="_blank">
          <img alt="linkedin button" src={linkedinIcon} />
        </Link>
      </Box>
      <Typography variant="h5" color={colors.black} className={classes.name}>
        {leader.name}
      </Typography>
      <Typography variant="body2" color={colors.black} className={classes.position}>
        {leader.position}
      </Typography>
      <Typography variant="body2" color={colors.black} className={classes.description}>
        {leader.description}
      </Typography>
    </Box>
  );
}

LeaderCard.propTypes = {
  leader: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.string,
    description: PropTypes.string,
    linkedin_url: PropTypes.string,
    photo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};

export default LeaderCard;
