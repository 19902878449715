import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    margin: '7.5rem 0 0 0',
    '@media (min-width: 900px)': {
      justifyContent: 'space-between',
      height: '100vh',
    },
  },
  wrongPathMessage: {
    margin: '0 0 0 2rem',
    '@media (min-width: 900px)': {
      margin: '0 0 0 10rem',
    },
  },
  alertText: {
    textTransform: 'uppercase',
  },
  description: {
    margin: '2rem 0 5rem 0',
    '@media (min-width: 900px)': {
      margin: '5rem 0 14rem 0',
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
    },
  },
  link: {
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  linkText: {
    '@media (min-width: 900px)': {
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
    },
  },
  imageContainer: {
    margin: '7.5rem 0 3rem 0',
    '@media (max-width: 900px)': {
      width: '100%',
    },
    '@media (min-width: 900px)': {
      margin: '0rem 10rem 0 0rem',
      width: '45%',
    },
    '@media (min-width: 1200px)': {
      margin: '-10rem 10rem 0 0rem',
      width: '50%',
    },
    '& img': {
      '@media (max-width: 320px)': {
        width: '30rem',
      },
      '@media (min-width: 900px)': {
        width: '100%',
      },
    },
  },
});

export default useStyles;
