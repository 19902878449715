import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import colors from '../../../helpers/colors';
import useStyles from './styles';
import ReferenceCard from '../../HomePage/WhatWeHaveDone/ReferenceCard';

function RecentAssessments({ visibleCaseStudies }) {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h2" colors={colors.black} align="left" className={classes.title}>
          RECENT ASSESSMENTS
        </Typography>
      </Grid>
      <Grid item display="flex" justifyContent="center">
        <Grid container flexDirection="row" justifyContent="center" className={classes.caseStudyContainer}>
          {visibleCaseStudies.map((caseStudy) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={caseStudy.id}
            >
              <ReferenceCard
                key={caseStudy.id}
                id={caseStudy.id}
                image={caseStudy.image ? caseStudy.image.url : ''}
                title={caseStudy.title}
                description={caseStudy.content}
                referenceLink={`/case-study/${caseStudy.meta_title.replaceAll(' ', '-')}`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

RecentAssessments.propTypes = {
  visibleCaseStudies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  })).isRequired,
};

export default RecentAssessments;
