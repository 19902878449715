import { createReducer } from '@reduxjs/toolkit';
import initialClients from './defaultState';
import { setClientsSuccess, setClientsError } from './actions';

const clientsReducer = createReducer(initialClients, (builder) => {
  builder
    .addCase(setClientsSuccess, (state, action) => {
      state.clients = action.payload;
    })
    .addCase(setClientsError, (state, action) => {
      state.errorMessage = action.payload;
    });
});

export default clientsReducer;
