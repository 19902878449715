import { makeStyles } from '@mui/styles';
import backgroundImage from '../../../img/what-we-do-background-rectangle.svg';
import colors from '../../../helpers/colors';

const useStyles = makeStyles({
  root: {
    margin: '0 0 8rem 0',
    '@media (min-width: 1200px)': {
      margin: '0',
    },
  },
  typography: {
    textTransform: 'uppercase',
    margin: '9.3rem 0 0 2rem',
    '@media (min-width: 1200px)': {
      margin: '10rem 0 0 10rem',
    },
  },
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    margin: '4rem 0 0 0',
    '& img': {
      marginTop: '6rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '31rem',
    },
  },
  typographyLarge: {
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    fontStyle: 'italic',
    margin: '2rem 2rem 4rem 2rem',
  },
  checkContainer: {
    borderBottom: `1px solid ${colors.gray}`,
    '@media (min-width: 1200px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '31rem',
      border: `1px solid ${colors.gray}`,
      margin: '8.3rem 0 0 0',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '2rem 0 0 2rem',
    '@media (min-width: 1200px)': {
      flexDirection: 'column',
      alignItems: 'center',
      margin: '6rem 0 0 0',
    },
  },
  checkIcon: {
    fontSize: '4rem',
    '@media (min-width: 1200px)': {
      '& svg': {
        display: 'block',
        margin: '0 auto 0 auto',
      },
    },
  },
  titleTypography: {
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    fontStyle: 'italic',
    marginLeft: '1.5rem',
  },
  typographyDescription: {
    fontStyle: 'italic',
    margin: '2rem',
    '@media (min-width: 1200px)': {
      textAlign: 'center',
    },
  },
});

export default useStyles;
