import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './store';

const { platform } = window.navigator;
const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

if (windowsPlatforms.indexOf(platform) !== -1) {
  document.getElementById('html').classList.add('windowsPlatform');
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
