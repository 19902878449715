import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import colors from '../../../../../helpers/colors';
import useStyles from './styles';
import MetaData from '../../../../MetaData';
import reCaptchaSiteKey from './reCaptchaSiteKey';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('This field is mandatory'),
  lastName: yup
    .string()
    .required('This field is mandatory'),
  emailInput: yup
    .string()
    .email('Enter a valid email')
    .required('This field is mandatory'),
  phoneInput: yup
    .number().typeError('Enter a valid phone number'),
  messageInput: yup
    .string()
    .required('Please correct errors before submitting this form.'),
  recaptchaValue: yup
    .string()
    .required(),
});

function Form({ setSubmitted, sendMail, mailResponseStatus }) {
  const classes = useStyles();
  const recaptchaRef = React.createRef();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailInput: '',
      phoneInput: '',
      messageInput: '',
      recaptchaValue: '',
    },
    validationSchema,
    onSubmit: (contactUsFormValues) => {
      sendMail(contactUsFormValues);
    },
  });

  useEffect(() => {
    recaptchaRef.current.execute();
  }, []);

  useEffect(() => {
    if (mailResponseStatus === 200) {
      formik.resetForm();
      setSubmitted(true);
    }
  }, [mailResponseStatus]);

  return (
    <>
      <MetaData
        metaDescription="Pop up contact form allowing clients to send a message with a question to Unicornly"
        title="Unicornly | Reach out to Unicornly"
      />
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container columnSpacing={{ sm: 2, lg: 8 }}>
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" className={classes.textFieldContainer}>
            <TextField
              variant="filled"
              label="*Your first name"
              className={classes.textField}
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" className={classes.textFieldContainer}>
            <TextField
              variant="filled"
              label="*Your last name"
              className={classes.textField}
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" className={classes.textFieldContainer}>
            <TextField
              variant="filled"
              label="*Your email"
              className={classes.textField}
              id="emailInput"
              name="emailInput"
              value={formik.values.emailInput}
              onChange={formik.handleChange}
              error={formik.touched.emailInput && Boolean(formik.errors.emailInput)}
              helperText={formik.touched.emailInput && formik.errors.emailInput}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" className={classes.textFieldContainer}>
            <TextField
              variant="filled"
              label="Your phone number"
              className={classes.textField}
              id="phoneInput"
              name="phoneInput"
              value={formik.values.phoneInput}
              onChange={formik.handleChange}
              error={formik.touched.phoneInput && Boolean(formik.errors.phoneInput)}
              helperText={formik.touched.phoneInput && formik.errors.phoneInput}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
            <TextField
              multiline
              rows={8}
              variant="filled"
              label="*Tell us about your needs"
              className={classes.messageTextField}
              id="messageInput"
              name="messageInput"
              value={formik.values.messageInput}
              onChange={formik.handleChange}
              error={formik.touched.messageInput && Boolean(formik.errors.messageInput)}
              helperText={formik.touched.messageInput && formik.errors.messageInput}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" className={classes.buttonContainer}>
            <Button className={classes.button} type="submit">
              <Typography variant="h3" color={colors.white} align="center" className={classes.buttonText}>
                Let’s talk!
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <ReCAPTCHA
          badge="bottomright"
          ref={recaptchaRef}
          size="invisible"
          sitekey={reCaptchaSiteKey}
          onChange={(recaptchaValue) => {
            formik.setFieldValue('recaptchaValue', recaptchaValue);
          }}
        />
      </form>
    </>

  );
}

Form.propTypes = {
  setSubmitted: PropTypes.func.isRequired,
  sendMail: PropTypes.func.isRequired,
  mailResponseStatus: PropTypes.number.isRequired,
};

export default Form;
