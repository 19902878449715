import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  referenceCardContainer: {
    margin: '0 0 12rem 0',
    '@media screen and (min-width: 900px)': {
      maxWidth: '90rem',
      margin: '0 0 20rem 0',
    },
    '@media screen and (min-width: 1200px)': {
      maxWidth: '125rem',
      margin: '0 0 20rem 0',
    },
  },
  title: {
    margin: '11.8rem 0 4rem 2rem',
    '@media screen and (min-width: 900px)': {
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
      margin: '12.1rem 0 4rem 10rem',
    },
  },
});

export default useStyles;
