import { useEffect, useState } from 'react';

function useMobileView() {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => (window.innerWidth < 900
      ? setMobileView(true) : setMobileView(false));

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      setMobileView();
    };
  }, []);

  return mobileView;
}

export default useMobileView;
