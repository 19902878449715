import React from 'react';
import { SvgIcon, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import colors from '../../../../helpers/colors';
import useMobileView from '../../../../hooks/useMobileView';

function DotIcon({
  isActive,
  dotStyle,
  dotTextStyle,
  text,
}) {
  const mobileView = useMobileView();

  return (
    <>
      <SvgIcon viewBox="0 0 60 60" className={dotStyle}>
        <circle
          cx="30"
          cy="30"
          r="25"
          fill={isActive ? colors.darkViolet : colors.whiteSmoke}
          stroke={isActive ? colors.lightViolet : colors.lightGray}
          strokeWidth="8"
        />
      </SvgIcon>
      {!mobileView && (
      <Typography
        variant="body2"
        color={isActive ? colors.darkViolet : colors.whiteSmoke}
        className={dotTextStyle}
      >
        {text}
      </Typography>
      )}
    </>

  );
}

DotIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dotStyle: PropTypes.string.isRequired,
  dotTextStyle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default DotIcon;
