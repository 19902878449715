import React, { useEffect, useState } from 'react';
import { CookieConsentBanner } from '@porscheofficial/cookie-consent-banner-react';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import tagManagerScript from './tagManagerScript';
import './styles.css';
import TagManager from './TagManager';

const CookieBanner = ({ children }) => {
  const [acceptedCategories, setAcceptedCategories] = useState([]);

  const acceptedCategoriesHandler = (event) => {
    setAcceptedCategories(event.detail.acceptedCategories ?? []);
  };

  useEffect(() => {
    window.addEventListener('cookie_consent_preferences_updated', acceptedCategoriesHandler);
    window.addEventListener('cookie_consent_preferences_restored', acceptedCategoriesHandler);
  }, []);

  return (
    <>
      <TagManager acceptedCategories={acceptedCategories} tagManagerScript={tagManagerScript} />
      {children}
      <CookieConsentBanner
        className="banner"
        btnLabelAcceptAndContinue="Agree and continue"
        btnLabelSelectAllAndContinue="Select all and continue"
        btnLabelOnlyEssentialAndContinue="Continue with technically required cookies only"
        btnLabelPersistSelectionAndContinue="Save selection and continue"
        contentSettingsDescription="You can decide which cookies are used by selecting the respective options below. Please note that your selection may impair in the functionality of the service."
        availableCategories={[
          {
            description: 'Enable you to navigate and use the basic functions and to store preferences.',
            key: 'technically_required',
            label: 'Technically necessary cookies',
            isMandatory: true,
          },
          {
            description: 'Enable us to determine how visitors interact with our service in order to improve the user experience and to offer and evaluate relevant content and interest-based advertising.',
            key: 'analysis_marketing_cookies',
            label: 'Analysis and marketing cookies',
          },
        ]}
      >
        <Box>
          We use cookies and similar technologies to provide certain features,
          enhance the user experience and deliver content that is relevant to your
          interests. Depending on their purpose, analysis and marketing cookies
          may be used in addition to technically necessary cookies. By clicking on
          &quot;Agree and continue&quot;, you declare your consent to the use of
          the aforementioned cookies.
          <Button
            onClick={() => document.dispatchEvent(new Event('cookie_consent_details_show'))}
            onKeyPress={() => document.dispatchEvent(new Event('cookie_consent_details_show'))}
          >
            Here
          </Button>
          you can make detailed settings or revoke your consent (in part if
          necessary) with effect for the future.
        </Box>
      </CookieConsentBanner>
    </>
  );
};

CookieBanner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CookieBanner;
