import { makeStyles } from '@mui/styles';
import contactUs from '../../../img/contact_us_btn_violet.svg';
import colors from '../../../helpers/colors';

const useStyles = makeStyles({
  root: {
    background: 'transparent',
    margin: '4rem 10rem 2.5rem 10rem',
  },
  logoLink: {
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer',
    '& img': {
      width: '20.4rem',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      borderBottom: '0.2rem solid',
      borderBottomColor: colors.violet,
    },
  },
  activeLink: {
    textDecoration: 'none',
    borderBottom: '0.2rem solid',
    borderBottomColor: colors.violet,
  },
  divider: {
    borderColor: colors.violet,
    borderStyle: '0.1rem, solid',
    margin: '0 10rem',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    maxWidth: '60rem',
    paddingLeft: '3rem',
  },
  typography: ({ color }) => ({
    color,
    margin: '0 0.5rem',
    cursor: 'pointer',
  }),
  button: {
    marginLeft: '2rem',
    minWidth: '10.8rem',
    height: '4.3rem',
    padding: 0,
    backgroundImage: `url(${contactUs})`,
    boxShadow: '0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0',
    '@media screen and (min-width: 1200px)': {
      minWidth: '14.2rem',
    },
  },
  buttonText: {
    fontWeight: '700',
    textTransform: 'none',
  },
});

export default useStyles;
