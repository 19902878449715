import React from 'react';
import successLogo from '../../../../img/contact-us-success.svg';
import useStyles from './styles';

const SuccessLogo = () => {
  const classes = useStyles();
  return (
    <img alt="send successfully" src={successLogo} className={classes.successLogo} />
  );
};

export default SuccessLogo;
