import { makeStyles } from '@mui/styles';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'none',
    '@media (min-width: 900px)': {
      backgroundImage: `linear-gradient(to right, ${colors.white} 50%, ${colors.violet} 50%)`,
      position: 'relative',
    },
  },
  logoContainer: {
    margin: '6.5rem 10rem 14.5rem 10rem',
  },
  logo: {
    '@media (min-width: 900px)': {
      display: 'block',
      width: '20rem',
      height: '5rem',
    },
  },
  exitButton: {
    color: colors.black,
    border: 'none',
    background: 'transparent',
    padding: 0,
    margin: '0 2rem 0 0',
    cursor: 'pointer',
    '& svg': {
      fontSize: '3rem',
    },
    '@media (min-width: 900px)': {
      color: colors.white,
      position: 'relative',
    },
  },
  intro: {
    margin: '2rem 0 1rem 2rem',
    '@media (min-width: 900px)': {
      margin: '2rem 0 1rem 10rem',
    },
  },
  description: {
    margin: '0 0 0 2rem',
    '@media (min-width: 900px)': {
      margin: '0 0 0 10rem',
    },
  },
  successIcon: {
    marginTop: '9rem',
  },
  homepageRedirect: {
    textTransform: 'uppercase',
    margin: '8rem 0 0 0',
    '& a': {
      textDecoration: 'none',
    },
    '@media (min-width: 900px)': {
      margin: '8rem 0 0 10rem',
      paddingBottom: '25rem',
      '& h4': {
        textAlign: 'left',
      },
    },
  },
});

export default useStyles;
