import { useEffect, useState } from 'react';

function useWindowHeight() {
  const [height, setHeight] = useState();

  useEffect(() => {
    const setResponsiveness = () => {
      setHeight(window.innerHeight);
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      setHeight();
    };
  }, []);

  return height;
}

export default useWindowHeight;
