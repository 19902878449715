import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { sendMail, setMailResponseStatus } from './actions';
import { MAIL_SERVICE_URL } from '../../services/apiConfig';

function* sendMailSaga(action) {
  try {
    const response = yield call(
      axios.post,
      MAIL_SERVICE_URL,
      action.payload.mailContent,
    );
    yield put(setMailResponseStatus(response.status));
  } catch (error) {
    yield put(setMailResponseStatus(0));
  }
}

function* watchSendMailSaga() {
  yield takeEvery(String(sendMail), sendMailSaga);
}

export default watchSendMailSaga;
