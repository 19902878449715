import { makeStyles } from '@mui/styles';
import card from '../../../../img/article_card.svg';
import cardDesktop from '../../../../img/article_card_desktop.svg';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  card: {
    backgroundImage: `url(${card})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '33.5rem',
    height: '35.7rem',
    boxSizing: 'border-box',
    marginBottom: '6rem',
    '@media screen and (max-width: 320px)': {
      width: '31.5rem',
    },
    '@media screen and (min-width: 900px)': {
      backgroundImage: `url(${cardDesktop})`,
      width: '40rem',
      height: '41.4rem',
      boxSizing: 'border-box',
      marginBottom: '4rem',
    },
  },
  imgContainer: {
    width: '32.7rem',
    height: '21.5rem',
    margin: '0.4rem 0.4rem 1.2rem 0.4rem',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
    },
    '@media screen and (max-width: 320px)': {
      width: '30.5rem',
      height: '21.5rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '39rem',
      height: '25.6rem',
      margin: '0.5rem 0.5rem 1.5rem 0.5rem',
    },
  },
  title: {
    margin: '0 1.7rem 1.2rem 1.7rem',
    height: '5rem',
    overflow: 'auto',
    '@media screen and (max-width: 320px)': {
      margin: '0 1.7rem',
    },
    '@media screen and (min-width: 900px)': {
      margin: '0 2rem 1.5rem 2rem',
      height: '6rem',
    },
  },
  divider: {
    borderColor: colors.lightGray,
    borderStyle: '0.1rem, solid',
    marginLeft: '1.7rem',
    marginRight: '11.1rem',
    '@media screen and (min-width: 900px)': {
      marginLeft: '2rem',
      marginRight: '13.3rem',
    },
  },
  readMore: {
    fontWeight: '700',
    lineHeight: '2.9rem',
    margin: '1.8rem 2.8rem 0 0',
    '@media screen and (min-width: 900px)': {
      marginRight: '5.8rem',
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
    },
  },
});

export default useStyles;
