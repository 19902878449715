import { connect } from 'react-redux';
import Statistics from './Statistics';
import { getErrorMessage, getStatistics } from '../../../store/statistics/selectors';
import { fetchStatistics } from '../../../store/statistics/actions';

const mapStateToProps = (state) => ({
  statistics: getStatistics(state),
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchStatistics: () => { dispatch(fetchStatistics()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
