import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    marginBottom: '7rem',
    '@media screen and (min-width: 900px)': {
      marginBottom: '14rem',
    },
  },
  title: {
    margin: '1rem 0 4rem 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '1rem 0 3rem 10rem',
    },
  },
  cardContainer: {
    maxWidth: '125rem',
  },
});

export default useStyles;
