import { makeStyles } from '@mui/styles';
import letsTalkButton from '../../../../../img/lets_talk_btn_violet.svg';

const useStyles = makeStyles({
  form: {
    margin: '1rem 2rem 2rem 2rem',
  },
  textFieldContainer: {
    width: '100%',
  },
  textField: {
    width: '100%',
    maxWidth: '33.5rem',
    marginBottom: '1.1rem',
    '& input': {
      fontSize: '1.8rem',
    },
    '& label': {
      fontSize: '1.8rem',
    },
    '& textarea': {
      fontSize: '1.8rem',
    },
    '& p': {
      fontSize: '1.6rem',
    },
  },
  messageTextField: {
    width: '100%',
    maxWidth: '33.5rem',
    marginBottom: '1.1rem',
    '& input': {
      fontSize: '1.8rem',
    },
    '& label': {
      fontSize: '1.8rem',
    },
    '& textarea': {
      fontSize: '1.8rem',
    },
    '& p': {
      fontSize: '1.6rem',
    },
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    width: '100%',
    maxWidth: '33.5rem',
    height: '7rem',
    padding: 0,
    backgroundImage: `url(${letsTalkButton})`,
    boxShadow: '0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0',
  },
  buttonText: {
    letterSpacing: 0,
    textTransform: 'none',
  },
  '@media screen and (min-width: 900px)': {
    form: {
      margin: '1rem 5.7rem 6rem 10rem',
    },
    textField: {
      maxWidth: '100%',
      marginBottom: '3rem',
    },
    messageTextField: {
      maxWidth: '100%',
      marginBottom: '6rem',
    },
    buttonContainer: {
      alignItems: 'flex-start',
    },
    button: {
      maxWidth: '14.2rem',
      height: '4.3rem',
    },
    buttonText: {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
    },
  },
});

export default useStyles;
