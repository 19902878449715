import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useStyles from './styles';
import colors from '../../../helpers/colors';
import useMobileView from '../../../hooks/useMobileView';
import TabsMobile from './TabsMobile/TabsMobile';
import TabsDesktop from './TabsDesktop/TabsDesktop';

const WhatWeProvide = () => {
  const mobileView = useMobileView();
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h2" color={colors.black} className={classes.typography}>
            What we provide
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          {mobileView ? <TabsMobile /> : <TabsDesktop />}
        </Grid>
      </Grid>
    </>
  );
};

export default WhatWeProvide;
