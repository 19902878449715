import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import useStyles from './styles';
import colors from '../../../../helpers/colors';
import ContactDialog from '../../../ContactDialog';
import pages from '../../../../helpers/pages';

function MobileViewDrawer({ isDrawerOpen, toggleDrawerVisibility }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allPages, setActivePage] = useState(pages);
  const location = useLocation();

  useEffect(() => {
    setActivePage((prevPages) => prevPages.map((page) => ({
      ...page,
      isActive: page.link === location.pathname,
    })));
  }, [location]);

  const handleLinkClick = () => {
    toggleDrawerVisibility();
  };

  const handleDialogState = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  return (
    <>
      <Drawer className={classes.drawer} classes={{ paper: classes.drawerPaper }} anchor="right" open={isDrawerOpen}>
        <Box display="flex" flexDirection="column" alignItems="flex-end" className={classes.container}>
          {allPages.map((page) => (
            <Link
              to={page.link}
              key={page.id}
              className={page.isActive ? classes.activeLink : classes.link}
            >
              <Typography variant="h3" color={colors.darkBlue} align="right" className={classes.typography} onClick={() => handleLinkClick()}>
                {page.description}
              </Typography>
            </Link>
          ))}
        </Box>
        <Button className={classes.contactButton} onClick={handleDialogState}>
          <Typography variant="h3" color={colors.white}>Contact Us</Typography>
        </Button>
      </Drawer>

      <ContactDialog dialogState={isDialogOpen} handleDialogState={handleDialogState} />
    </>
  );
}

MobileViewDrawer.propTypes = {
  toggleDrawerVisibility: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default MobileViewDrawer;
