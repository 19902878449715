import { useEffect, useState } from 'react';
import globalVariables from '../helpers/globalVariables';
import useWindowHeight from './useWindowHeight';

function useScrolling(sectionHeight) {
  const height = useWindowHeight();
  const [isScrolled, setIsScrolled] = useState(false);

  const scrollHandler = () => {
    if (window.scrollY < height
            || window.scrollY
        > document.body.clientHeight - globalVariables.footerHeight - sectionHeight) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', () => setIsScrolled(false));
    };
  }, [height]);

  return isScrolled;
}

export default useScrolling;
