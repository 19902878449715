import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import useStyles from './styles';
import ReferenceCard from './ReferenceCard/ReferenceCard';
import colors from '../../../helpers/colors';

function WhatWeHaveDone({ fetchCaseStudies, caseStudies }) {
  const classes = useStyles();
  const [homeCaseStudies, setHomeCaseStudies] = useState([]);

  useEffect(() => {
    if (caseStudies.length === 0) {
      fetchCaseStudies();
    }
  }, []);

  useEffect(() => {
    setHomeCaseStudies(caseStudies.filter(
      (caseStudy) => caseStudy.is_visible_on_home_page && caseStudy.is_visible,
    ).slice(0, 3));
  }, [caseStudies]);

  return (
    <>
      <Typography variant="h2" colors={colors.black} align="left" className={classes.typography}>
        WHAT WE HAVE DONE
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.container}>
        <Typography variant="h5" colors={colors.black} align="left" className={classes.intro}>
          <span>A proven track record </span>
          working with start-ups and Fortune 500 companies
        </Typography>
        <Typography variant="body2" colors={colors.black} align="left" className={classes.description}>
          Over the course of the last 20+ years, we have successfully delivered
          technology consulting for renowned brands, from start-ups, through scale ups,
          to Fortune 500 companies. We have a proven track record of delivering effective
          tech due diligence assessments for PE, VC and corporate investors.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.caseStudyContainer}>
        {homeCaseStudies.map((caseStudy) => (
          <ReferenceCard
            key={caseStudy.id}
            id={caseStudy.id}
            image={caseStudy.image !== null ? caseStudy.image.url : ''}
            title={caseStudy.title}
            description={caseStudy.content}
            referenceLink={`/case-study/${caseStudy.meta_title.replaceAll(' ', '-')}`}
          />
        ))}
      </Box>
    </>
  );
}

WhatWeHaveDone.propTypes = {
  fetchCaseStudies: PropTypes.func.isRequired,
  caseStudies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    meta_title: PropTypes.string,
  })).isRequired,
};

export default WhatWeHaveDone;
