import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../helpers/colors';
import ArticleReferenceCard from './ArticleReferenceCard';
import useMobileView from '../../../hooks/useMobileView';

function OurKnowledgeSection({ visibleArticles }) {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  const [articlesShown, setArticlesShown] = useState(3);
  const mobileView = useMobileView();

  useEffect(() => {
    if (mobileView) {
      setArticles(visibleArticles.slice(0, articlesShown));
    } else {
      setArticles(visibleArticles);
    }
  }, [articlesShown, mobileView, visibleArticles]);

  const handleLoadMoreArticlesClick = () => {
    setArticlesShown(((prevState) => prevState + 3));
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Typography variant="h2" colors={colors.black} align="left" className={classes.title}>
          OUR KNOWLEDGE
        </Typography>
      </Grid>
      <Grid item display="flex" justifyContent="center">
        <Grid container direction="row" justifyContent="center" className={classes.referenceCardContainer}>
          {articles.map((article) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={article.id}
            >
              <ArticleReferenceCard
                id={article.id}
                image={article.image ? article.image.url : ''}
                title={article.title}
                referenceLink={`/article/${article.meta_title.replaceAll(' ', '-')}`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      { mobileView && articlesShown < visibleArticles.length && (
      <Grid item display="flex" justifyContent="center">
        <Button className={classes.loadMoreButton} onClick={handleLoadMoreArticlesClick}>
          <Typography variant="h5" color={colors.violet} align="right" className={classes.loadMoreText}>
            LOAD MORE ARTICLES
          </Typography>
        </Button>
      </Grid>
      )}
    </Grid>
  );
}

OurKnowledgeSection.propTypes = {
  visibleArticles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    date: PropTypes.string,
    intro: PropTypes.string,
    is_visible: PropTypes.bool,
    article: PropTypes.arrayOf(PropTypes.any),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    meta_title: PropTypes.string,
    category_name: PropTypes.string,
  })).isRequired,
};

export default OurKnowledgeSection;
