import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchLeaderships, setLeadershipsSuccess, setLeadershipsError } from './actions';
import getContent from '../../services/getContent';
import { LEADERSHIPS_URL } from '../../services/apiConfig';

function* fetchLeadershipsSaga() {
  try {
    const result = yield call(getContent, LEADERSHIPS_URL);
    yield put(setLeadershipsSuccess(result));
  } catch (error) {
    yield put(setLeadershipsError(`Leaderships - data fetching failed: ${error}`));
  }
}

export default function* watchFetchLeadershipsSaga() {
  yield takeEvery(String(fetchLeaderships), fetchLeadershipsSaga);
}
