import React, { useLayoutEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import colors from '../../helpers/colors';
import useStyles from './styles';
import notFoundImage from '../../img/not-found-mobile.svg';
import Footer from '../Footer';
import navBarThemes from '../../helpers/navBarThemes';

function NotFoundPage({
  linkColor, changeLink, navBarTheme, changeTheme,
}) {
  const classes = useStyles();

  useLayoutEffect(() => {
    changeLink(colors.black);
    changeTheme(navBarThemes.dark);
  }, [linkColor, navBarTheme]);

  const resetNavbarTheme = () => {
    changeLink(colors.white);
    changeTheme(navBarThemes.light);
  };

  return (
    <>
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid container display="flex" flexDirection="row" alignItems="center" className={classes.root}>
            <Box display="flex" flexDirection="column" className={classes.wrongPathMessage}>
              <Typography variant="h1" color={colors.black} align="left" className={classes.alertText}>
                Oops...
              </Typography>
              <Typography variant="h5" color={colors.black} align="left" className={classes.description}>
                Something went wrong...
              </Typography>
              <Link to="/" className={classes.link} onClick={resetNavbarTheme}>
                <Typography variant="h5" color={colors.violet} align="left" className={classes.linkText}>
                  Go back to home page
                </Typography>
              </Link>
            </Box>
            <Box display="flex" justifyContent="center" className={classes.imageContainer}>
              <img alt="page not found" src={notFoundImage} />
            </Box>
          </Grid>
          <Footer />
        </Grid>
      </Fade>
    </>
  );
}

NotFoundPage.propTypes = {
  changeLink: PropTypes.func.isRequired,
  linkColor: PropTypes.string.isRequired,
  changeTheme: PropTypes.func.isRequired,
  navBarTheme: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NotFoundPage;
