import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    margin: '2.5rem 2rem 0 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '6.5rem 3rem 0 0',
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '1.1rem',
    },
  },
  typography: {
    textTransform: 'uppercase',
    alignSelf: 'flex-end',
    '@media screen and (min-width: 900px)': {
      fontSize: '1.6rem',
      lineHeight: '1.9rem',
    },
  },
});

export default useStyles;
