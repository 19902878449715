import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    margin: '4rem 0 6rem 2rem',
    '@media screen and (min-width: 900px)': {
      width: '50%',
      margin: '4.8rem 0 6rem 0',
    },
  },
  title: {
    marginBottom: '2rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 1.3rem 10rem',
    },
  },
  address: {
    width: '19.2rem',
    height: '8.8rem',
    marginBottom: '1.3rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 1.3rem 10rem',
    },
  },
  email: {
    '@media screen and (min-width: 900px)': {
      marginLeft: '10rem',
    },
  },
});

export default useStyles;
