import { createReducer } from '@reduxjs/toolkit';
import initialTestimonials from './defaulState';
import { setTestimonialsSuccess, setTestimonialsError } from './actions';

const testimonialsReducer = createReducer(initialTestimonials, (builder) => {
  builder
    .addCase(setTestimonialsSuccess, (state, action) => {
      state.testimonials = action.payload;
    })
    .addCase(setTestimonialsError, (state, action) => {
      state.errorMessage = action.payload;
    });
});

export default testimonialsReducer;
