import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: '9rem',
    '@media screen and (min-width: 900px)': {
      marginBottom: '16rem',
    },
  },
  title: {
    margin: '11.8rem 0 4rem 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '12.1rem 0 4rem 10rem',
    },
  },
  referenceCardContainer: {
    '@media screen and (min-width: 900px)': {
      maxWidth: '90rem',
    },
    '@media screen and (min-width: 1200px)': {
      maxWidth: '125rem',
    },
  },
  loadMoreButton: {
    padding: '0',
  },
  loadMoreText: {
    fontWeight: '700',
  },
});

export default useStyles;
