import React from 'react';
import AppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import useStyles from './styles';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import useMobileView from '../../hooks/useMobileView';
import colors from '../../helpers/colors';

function NavBar({ visibility, transparency }) {
  const mobileView = useMobileView();

  const classes = useStyles(transparency
    ? { background: 'transparent' } : { background: colors.white });

  return (
    visibility
      ? (
        <AppBar className={classes.root}>
          {mobileView ? <MobileView /> : <DesktopView />}
        </AppBar>
      ) : null
  );
}

NavBar.propTypes = {
  visibility: PropTypes.bool.isRequired,
  transparency: PropTypes.bool.isRequired,
};

export default NavBar;
