import { makeStyles } from '@mui/styles';
import contactUs from '../../../img/contact_us_btn_violet.svg';
import catchUs from '../../../img/catch_us_btn.png';

const useStyles = makeStyles({
  container: {
    marginLeft: '2rem',
    '@media screen and (min-width: 900px)': {
      width: '50%',
      margin: 0,
    },
  },
  content: {
    margin: '0 2rem 2rem 0',
    '@media screen and (min-width: 900px)': {
      width: '37rem',
      margin: '0 0 2.9rem 0',
    },
  },
  title: {
    marginBottom: '2rem',
    '@media screen and (min-width: 900px)': {
      margin: '4.8rem 0 1.3rem 0',
    },
  },
  buttonContainer: {
    '@media screen and (min-width: 900px)': {
      flexDirection: 'row',
      paddingRight: '10rem',
    },
  },
  contactUsBtn: {
    marginBottom: '3rem',
    width: '22.5rem',
    height: '5rem',
    padding: 0,
    backgroundImage: `url(${contactUs})`,
    borderRadius: '0',
    '@media screen and (min-width: 900px)': {
      margin: '0 2rem 0 0',
    },
  },
  contactUsBtnText: {
    fontWeight: '700',
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    textTransform: 'none',
  },
  catchUsLink: {
    marginBottom: '4rem',
    height: '5rem',
    backgroundImage: `url(${catchUs})`,
    textDecoration: 'none',
    '@media screen and (min-width: 320px)': {
      width: '28rem',
    },
    '@media screen and (min-width: 375px)': {
      width: '29.2rem',
    },
  },
  catchUsLinkText: {
    marginRight: '0.4rem',
    fontWeight: '700',
    fontSize: '2.4rem',
    lineHeight: '2.5rem',
    textTransform: 'none',
    '@media screen and (min-width: 900px)': {
      whiteSpace: 'normal',
    },
    '@media screen and (min-width: 1200px)': {
      whiteSpace: 'nowrap',
    },
  },
  catchUsIcon: {
    margin: '1rem 1.2rem 1rem 2.4rem',
    width: '3rem',
    height: '3rem',
  },
});

export default useStyles;
