import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 0 10rem 0',
    '@media (min-width: 900px)': {
      margin: '0 0 20rem 0',
    },
  },
  typography: {
    textTransform: 'uppercase',
    margin: '4rem 2rem 1rem 2rem',
    '@media (min-width: 900px)': {
      margin: '4rem 2rem 4rem 10rem',
    },
  },
  container: {
    '@media (min-width: 1200px)': {
      margin: '0 0 0 3rem',
    },
  },
}));

export default useStyles;
