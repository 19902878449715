import { makeStyles } from '@mui/styles';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  introContainer: ({ backgroundImage, backgroundPosition }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition,
    backgroundSize: 'cover',
    height: '100vh',
    '@media (min-width: 900px)': {
      backgroundPosition,
      transform: 'translateX(100%)',
    },
  }),
  container: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  title: {
    margin: '0 2rem 2rem 2rem',
    maxWidth: '60rem',
    '@media screen and (min-width: 900px)': {
      color: colors.black,
      margin: '0 0 5rem 10rem',
      maxWidth: '35%',
    },
    '@media screen and (max-width: 320px)': {
      fontSize: '6rem',
      lineHeight: '5.8rem',
    },
  },
  homepageTitle: {
    fontSize: '4.8rem',
    lineHeight: '5.8rem',
    color: colors.black,
    margin: '0 2rem 2rem 2rem',
    maxWidth: '60rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 5rem 10rem',
      maxWidth: '35%',
    },
  },
  description: {
    margin: '0 2rem 3rem 2rem',
    '& span': {
      color: colors.white,
    },
    '@media screen and (min-width: 900px)': {
      color: colors.black,
      margin: '0 0 5rem 10rem',
      maxWidth: '35%',
      '& span': {
        color: colors.violet,
      },
    },
  },
  mainDescription: {
    color: colors.black,
    margin: '0 2rem 3rem 2rem',
    '& span': {
      color: colors.white,
      fontWeight: '700',
    },
    '@media screen and (min-width: 900px)': {
      color: colors.black,
      margin: '0 0 5rem 10rem',
      maxWidth: '35%',
      '& span': {
        color: colors.violet,
      },
    },
  },
});

export default useStyles;
