import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import useMobileView from '../../hooks/useMobileView';
import useStyles from './styles';
import LogoSide from './LogoSide';
import FormSide from './FormSide';

const ContactDialog = ({ dialogState, handleDialogState }) => {
  const classes = useStyles();
  const isDialogFullscreen = useMobileView();
  const [submitted, setSubmitted] = useState(false);
  return (
    <Dialog fullScreen={isDialogFullscreen} maxWidth="xl" open={dialogState} onClose={handleDialogState} scroll="body">
      <Grid container className={classes.root}>
        <FormSide
          submitted={submitted}
          setSubmitted={setSubmitted}
          handleDialogState={handleDialogState}
        />
        {!isDialogFullscreen && <LogoSide submitted={submitted} />}
      </Grid>
    </Dialog>
  );
};

ContactDialog.propTypes = {
  dialogState: PropTypes.bool.isRequired,
  handleDialogState: PropTypes.func.isRequired,
};

export default ContactDialog;
