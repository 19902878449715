import { connect } from 'react-redux';
import Card from './Card';
import { fetchClients } from '../../../../store/clients/actions';
import { getErrorMessage, getClients } from '../../../../store/clients/selectors';

const mapStateToProps = (state) => ({
  clients: getClients(state),
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchClients: () => { dispatch(fetchClients()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
