import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  testimonialsContainer: {
    marginTop: '6rem',
    '@media screen and (max-width: 320px)': {
      maxWidth: '32rem',
    },
    '@media screen and (min-width: 900px)': {
      marginTop: '15rem',
    },
  },
});

export default useStyles;
