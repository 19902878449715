import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useStyles from './styles';
import colors from '../../../helpers/colors';

const HowWeDoIt = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h2" color={colors.black} align="left" className={classes.typography}>
            How we do it
          </Typography>
        </Grid>
        <Grid container className={classes.howWeDoItContent}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" color={colors.black} className={classes.typographyLarge}>
              Technology
            </Typography>
            <Typography variant="body2" color={colors.darkGray} align="left" className={classes.typographyDescription}>
              As a part of our assessment, we put a spotlight on the tech part of the
              business. We analyze architecture and infrastructure to validate its
              performance and scalability. We take a close look at the quality and security
              of the codebase to understand potential vulnerabilities. We also assess IP and
              open source components to get the widest possible perspective of the technology
              solutions in use.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" color={colors.black} className={classes.typographyLarge}>
              People & Processes
            </Typography>
            <Typography variant="body2" color={colors.darkGray} align="left" className={classes.typographyDescription}>
              During our evaluations, we pay special attention to people. We assess the
              technical team and its composition, organization and competence. We evaluate a
              company’s culture and how it attracts and retains talent. We understand HR
              processes in order to suggest best practices and ways to improve. Product and
              software development processes are also assessed, including methodologies and tools.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" color={colors.black} align="left" className={classes.typographyLarge}>
              Digital Strategy
            </Typography>
            <Typography variant="body2" color={colors.darkGray} align="left" className={classes.typographyDescription}>
              Assessing digital strategy is an important part of tech DD. We focus attention
              on a product’s vision, strategy and roadmap to determine whether it is
              reasonable, feasible, and aligned with sales targets. We go through customer
              acquisition plans to understand the growth potential, with particular emphasis
              on user retention, engagement and upselling. Moreover, we perform market
              analysis to identify opportunities for disruption and to properly position the brand.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HowWeDoIt;
