import { all } from 'redux-saga/effects';
import { watchFetchCaseStudiesSaga, watchFetchCaseStudySaga } from './caseStudies/saga';
import { watchFetchArticleSaga, watchFetchArticlesSaga } from './articles/saga';
import watchFetchTestimonialsSaga from './testimonials/saga';
import watchFetchClientsSaga from './clients/saga';
import watchFetchStatisticsSaga from './statistics/saga';
import watchFetchLeadershipsSaga from './leaderships/saga';
import watchSendMailSaga from './mailService/saga';
import {
  watchChangeLinkColorSaga,
  watchChangeThemeSaga,
  watchChangeTransparencySaga,
  watchChangeVisibilitySaga,
} from './navBar/saga';

export default function* rootSaga() {
  yield all([
    watchFetchLeadershipsSaga(),
    watchFetchCaseStudiesSaga(),
    watchFetchCaseStudySaga(),
    watchFetchArticlesSaga(),
    watchFetchArticleSaga(),
    watchFetchTestimonialsSaga(),
    watchFetchClientsSaga(),
    watchFetchStatisticsSaga(),
    watchChangeVisibilitySaga(),
    watchChangeTransparencySaga(),
    watchChangeLinkColorSaga(),
    watchChangeThemeSaga(),
    watchSendMailSaga(),
  ]);
  yield;
}
