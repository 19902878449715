import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import useStyles from './styles';
import colors from '../../helpers/colors';
import Content from './Content/Content';
import Footer from '../Footer';
import RecentAssessments from '../ExperiencePage/RecentAssessments';
import useScrolling from '../../hooks/useScrolling';
import globalVariables from '../../helpers/globalVariables';
import MetaData from '../MetaData';

const CaseStudies = ({
  fetchCaseStudies,
  caseStudies,
  fetchCurrentCaseStudy,
  currentCaseStudy,
}) => {
  const location = useLocation();
  const caseStudyId = location.state;
  const [visibleCaseStudies, setVisibleCaseStudies] = useState([]);
  const isScrolled = useScrolling(globalVariables.recentAssessmentsHeight);
  const classes = useStyles({
    backgroundImage: currentCaseStudy.image ? currentCaseStudy.image.url : '',
  });

  useEffect(() => {
    fetchCurrentCaseStudy(caseStudyId);
  }, [caseStudyId]);

  useEffect(() => {
    if (caseStudies.length === 0) {
      fetchCaseStudies();
    }
  }, []);

  useEffect(() => {
    setVisibleCaseStudies(caseStudies.filter(
      (caseStudy) => caseStudy.id !== currentCaseStudy.id && caseStudy.is_visible,
    ).slice(0, 3));
  }, [caseStudies, currentCaseStudy]);

  return (
    <>
      <MetaData
        metaDescription={`${currentCaseStudy.meta_title} | ${currentCaseStudy.industry_name} | Unicornly`}
        title={`${currentCaseStudy.meta_title} | ${currentCaseStudy.industry_name} | Unicornly`}
      />
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid item>
            <Grid item md={6} className={classes.root} />
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className={classes.container}
            >
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                className={!isScrolled ? classes.logos : classes.scrolledLogos}
              >
                <Box className={!isScrolled
                  ? [classes.logoPicture, classes.logoPictureLeft]
                  : classes.scrolledLogoPicture}
                >
                  {currentCaseStudy.logo_1 && <img alt="logo" src={currentCaseStudy.logo_1.url} />}
                </Box>
                {isScrolled && (currentCaseStudy.logo_1 || currentCaseStudy.logo_2) && (
                <Box display="flex" flexDirection="row" alignItems="center" className={classes.scrolledDividerContainer}>
                  <Divider className={classes.scrolledDivider} />
                  <CircleIcon htmlColor={colors.gray} className={classes.scrolledDot} />
                </Box>
                )}
                <Box className={!isScrolled
                  ? [classes.logoPicture, classes.logoPictureRight]
                  : classes.scrolledLogoPicture}
                >
                  {currentCaseStudy.logo_2 && <img alt="logo" src={currentCaseStudy.logo_2.url} />}
                </Box>
              </Grid>
              {(currentCaseStudy.logo_1 || currentCaseStudy.logo_2)
                  && <Divider className={classes.divider}><CircleIcon /></Divider>}
              <Typography variant="h3" color={colors.white} align="left" className={classes.title}>
                {currentCaseStudy.title}
              </Typography>
              <Typography variant="h4" color={colors.black} align="left" className={classes.description}>
                {currentCaseStudy.content}
              </Typography>
            </Grid>
          </Grid>
          <Content caseStudy={currentCaseStudy} />
          <Divider className={classes.assessmentDivider} />
          <Box className={classes.assessmentsContainer}>
            <RecentAssessments visibleCaseStudies={visibleCaseStudies} />
          </Box>
          <Footer />
        </Grid>
      </Fade>
    </>
  );
};

CaseStudies.propTypes = {
  fetchCurrentCaseStudy: PropTypes.func.isRequired,
  currentCaseStudy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    logo_1: PropTypes.shape({
      url: PropTypes.string,
    }),
    logo_2: PropTypes.shape({
      url: PropTypes.string,
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    meta_title: PropTypes.string,
    industry_name: PropTypes.string,
  }).isRequired,
  fetchCaseStudies: PropTypes.func.isRequired,
  caseStudies: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CaseStudies;
