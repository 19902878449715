import { connect } from 'react-redux';
import WhatWeHaveDone from './WhatWeHaveDone';
import { fetchCaseStudies } from '../../../store/caseStudies/actions';
import { getCaseStudies, getErrorMessage } from '../../../store/caseStudies/selectors';

const mapStateToProps = (state) => ({
  caseStudies: getCaseStudies(state),
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCaseStudies: () => { dispatch(fetchCaseStudies()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatWeHaveDone);
