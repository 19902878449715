import { createReducer } from '@reduxjs/toolkit';
import initialArticles from './defaultState';
import {
  setArticlesSuccess, setArticlesError, setArticleSuccess,
} from './actions';

const articlesReducer = createReducer(initialArticles, (builder) => {
  builder
    .addCase(setArticleSuccess, (state, action) => {
      state.article = action.payload;
    })
    .addCase(setArticlesSuccess, (state, action) => {
      state.articles = action.payload;
    })
    .addCase(setArticlesError, (state, action) => {
      state.errorMessage = action.payload;
    });
});

export default articlesReducer;
