import { makeStyles } from '@mui/styles';
import colors from '../../../helpers/colors';
import smallEllipse from '../../../img/what-we-do-small-ellipse.svg';
import mediumEllipse from '../../../img/what-we-do-medium-ellipse.svg';
import bigEllipse from '../../../img/what-we-do-big-ellipse.svg';
import smallEllipseDesktop from '../../../img/what-we-do-desktop-ellipse-small.svg';
import mediumEllipseDesktop from '../../../img/what-we-do-desktop-ellipse-medium.svg';
import bigEllipseDesktop from '../../../img/what-we-do-desktop-ellipse-big.svg';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.whiteSmoke,
    backgroundImage: `url(${smallEllipse}), url(${mediumEllipse}), url(${bigEllipse})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '5rem 0 10rem 0',
    '@media (min-width: 900px)': {
      backgroundImage: `url(${smallEllipseDesktop}), url(${mediumEllipseDesktop}), url(${bigEllipseDesktop})`,
      margin: '20rem 0 15rem 0',
    },
  },
  howWeDoItContent: {
    margin: '0 0 0 2rem',
    '@media (min-width: 900px)': {
      margin: '0 8rem 0 10rem',
    },
  },
  typography: {
    textTransform: 'uppercase',
    margin: '5rem 0 0 2rem',
    '@media (min-width: 900px)': {
      margin: '5rem 0 0 10rem',
    },
  },
  typographyLarge: {
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    fontStyle: 'italic',
    margin: '4rem 2rem 1rem 0',
  },
  typographyDescription: {
    fontStyle: 'italic',
    margin: '1rem 4rem 0 0',
  },
}));

export default useStyles;
