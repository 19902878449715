import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useStyles from './styles';
import Card from './Card';
import colors from '../../../helpers/colors';

function WeWorkWithTheBest() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" color={colors.black} textAlign="left" className={classes.typography}>
        WE WORK WITH THE BEST
      </Typography>
      <Box display="flex" justifyContent="center" className={classes.backgroundContainer}>
        <Card />
      </Box>
    </>
  );
}

export default WeWorkWithTheBest;
