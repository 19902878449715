import { connect } from 'react-redux';
import ExperiencePage from './ExperiencePage';
import { fetchCaseStudies } from '../../store/caseStudies/actions';
import { getCaseStudies } from '../../store/caseStudies/selectors';

const mapStateToProps = (state) => ({
  caseStudies: getCaseStudies(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCaseStudies: () => dispatch(fetchCaseStudies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencePage);
