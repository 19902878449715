import { makeStyles } from '@mui/styles';
import rectangle from '../../../../img/what-we-do-rectangle2.svg';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  rectangle: {
    backgroundImage: `url(${rectangle})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '31.5rem',
    height: '28.1rem',
    padding: '0',
    margin: '1rem',
    '@media (max-width: 320px)': {
      backgroundSize: '28rem 26rem',
      width: '28rem',
      height: '26rem',
    },
    '& img': {
      margin: '2rem 0 0 3rem',
    },
  },
  typographyTitle: {
    fontStyle: 'italic',
    textTransform: 'capitalize',
    margin: '3.2rem 0 0 3rem',
    '@media (max-width: 320px)': {
      margin: '3.2rem 0 0 1rem',
    },
  },
  descriptionContainer: {
    padding: '2rem 0 0 0',
  },
  description: {
    fontStyle: 'italic',
    '& ul': {
      margin: '0 0 0 0.8rem',
      '& li': {
        margin: '0 0 1rem 0',
        maxWidth: '22rem',
      },
    },
  },
  buttonContainer: {
    height: '1rem',
    margin: '0 2rem 2rem 2rem',
    '& svg': {
      fontSize: '3rem',
      color: colors.gray,
      cursor: 'pointer',
    },
  },
});

export default useStyles;
