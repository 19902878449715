import axios from 'axios';

function* getContent(url) {
  const response = yield axios.get(url);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error();
}

export default getContent;
