import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  assessmentsContainer: {
    marginBottom: '6rem',
    '@media screen and (min-width: 900px)': {
      marginBottom: '12.5rem',
    },
  },
});

export default useStyles;
