import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Link as ExternalLink } from '@mui/material';
import Grid from '@mui/material/Grid';
import useStyles from './styles';
import ContactSection from './ContactSection';
import LetsTalkSection from './LetsTalkSection';
import logo from '../../img/logo_light.png';
import colors from '../../helpers/colors';
import pages from '../../helpers/pages';

function Footer() {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Grid container display="flex" flexDirection="column" className={classes.container}>
        <Grid item xs={12} md={6}>
          <img className={classes.logo} alt="unicornly-logo" src={logo} />
          <Typography variant="body2" color={colors.white} className={classes.intro}>
            We will help you build the next unicorn
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.linksContainer}>
          {pages.map((page) => (
            <Link to={page.link} key={page.id} className={classes.link}>
              <Typography variant="body2" color={colors.white} className={classes.linkText}>
                {page.description}
              </Typography>
            </Link>
          ))}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Box display="flex" flexDirection="column" className={classes.container}>
        <ContactSection />
        <LetsTalkSection />
      </Box>
      <Divider className={classes.divider} />
      <Typography variant="subtitle2" color={colors.white} className={classes.footer}>
        © 2020 bPolNet sp. z o.o.  |  Made with love by bPol Icons by Icons8.
        This website uses cookies. By browsing it, you agree to our Privacy Policy.
        Powered by
        {' '}
        <ExternalLink href="https://primotly.com/" target="_blank">
          Primotly Software house.
        </ExternalLink>
      </Typography>
    </Box>
  );
}

export default Footer;
