import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import OurKnowledgeSection from './OurKnowledgeSection';
import LetsTalkToday from '../HomePage/LetsTalkToday';
import MetaData from '../MetaData';
import backgroundImage from '../../img/background_blog_desktop.png';
import pageContent from '../Intro/PagesIntroContent';
import Intro from '../Intro';

function BlogPage({ fetchArticles, articles }) {
  const [visibleArticles, setVisibleArticles] = useState([]);

  useEffect(() => {
    if (articles.length === 0) {
      fetchArticles();
    }
  }, []);

  useEffect(() => {
    setVisibleArticles(articles.filter(
      (caseStudy) => caseStudy.is_visible,
    ));
  }, [articles]);

  return (
    <>
      <MetaData
        metaDescription="Base of articles about the private equity, venture capital and digital due diligence industry."
        title="Digital Transformation Knowledge Base | Unicornly"
      />
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid item>
            <Intro
              backgroundImage={backgroundImage}
              title={pageContent.BlogPage.pageTitle}
              description={pageContent.BlogPage.description}
            />
          </Grid>
          <Grid item>
            <OurKnowledgeSection visibleArticles={visibleArticles} />
          </Grid>
          <Grid item>
            <LetsTalkToday />
          </Grid>
        </Grid>
      </Fade>
    </>

  );
}

BlogPage.propTypes = {
  fetchArticles: PropTypes.func.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    date: PropTypes.string,
    intro: PropTypes.string,
    is_visible: PropTypes.bool,
    article: PropTypes.arrayOf(PropTypes.any),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    meta_title: PropTypes.string,
    category_name: PropTypes.string,
  })).isRequired,
};

export default BlogPage;
