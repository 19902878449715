import React from 'react';
import { Typography, Grid } from '@mui/material';
import useStyles from './styles';
import tabsContent from '../tabsContent';
import colors from '../../../../helpers/colors';

const TabsDesktop = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.tabsContainer}>
        {tabsContent.map((item) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            className={classes.rectangle}
            key={item.primary.id}
          >
            <Grid item display="flex" alignItems="center">
              <img src={item.primary.icon} alt="tab icon" />
              <Typography variant="body1" color={colors.black} className={classes.typographyTitle}>
                {item.primary.title}
              </Typography>
            </Grid>
            <Grid item className={classes.descriptionContainer}>
              <Typography
                component="span"
                variant="body2"
                color={colors.black}
                align="left"
                className={classes.description}
              >
                <ul>
                  {item.primary.descriptions.map((description) => (
                    <li key={description.id}>{description.value}</li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
        ))}
        {tabsContent.map((item) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            className={classes.rectangle}
            key={item.secondary.id}
          >
            <Grid item display="flex" alignItems="center">
              <img src={item.secondary.icon} alt="tab icon" />
              <Typography component="span" variant="body1" color={colors.black} className={classes.typographyTitle}>
                {item.secondary.title}
              </Typography>
            </Grid>
            <Grid item className={classes.descriptionContainer}>
              <Typography
                component="span"
                variant="body2"
                color={colors.black}
                align="left"
                className={classes.description}
              >
                <ul>
                  {item.secondary.descriptions.map((description) => (
                    <li key={description.id}>{description.value}</li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TabsDesktop;
