import { makeStyles } from '@mui/styles';
import backgroundImage from '../../../img/about_us_triangle.svg';
import backgroundImageDesktop from '../../../img/about_us_triangle_desktop.svg';
import colors from '../../../helpers/colors';

const useStyles = makeStyles({
  title: {
    margin: '12.8rem 0 2rem 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '20rem 0 2rem 10rem',
    },
  },
  intro: {
    margin: '0 3rem 3rem 3rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 0 11rem',
      maxWidth: '80%',
    },
  },
  container: {
    '@media screen and (min-width: 900px)': {
      marginTop: '20rem',
    },
  },
  triangleContainer: {
    position: 'relative',
    width: '33rem',
    height: '29rem',
    '@media screen and (max-width: 320px)': {
      width: '30rem',
      height: '26rem',
    },
    '@media screen and (min-width: 900px)': {
      marginLeft: '10%',
      width: '42.3rem',
      height: '48.8rem',
    },
  },
  triangle: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    width: '33rem',
    height: '29rem',
    '@media screen and (max-width: 320px)': {
      width: '30rem',
      height: '26rem',
    },
    '@media screen and (min-width: 900px)': {
      backgroundImage: `url(${backgroundImageDesktop})`,
      width: '42.3rem',
      height: '48.8rem',
    },
  },
  logo: {
    display: 'none',
    '@media screen and (min-width: 900px)': {
      display: 'block',
      position: 'absolute',
      margin: 'auto',
      top: '0',
      left: '7rem',
      bottom: '0',
      right: '0',
      width: '17.1rem',
      height: '4.4rem',
    },
  },
  leftDot: {
    fontSize: '2.7rem',
    position: 'absolute',
    left: '-1.2rem',
    top: '-1rem',
    '@media screen and (max-width: 320px)': {
      left: '-1rem',
      top: '-1.15rem',
    },
    '@media screen and (min-width: 900px)': {
      fontSize: '4.2rem',
      left: '-2.1rem',
      top: '22.2rem',
    },
  },
  rightDot: {
    fontSize: '2.7rem',
    position: 'absolute',
    right: '-1.2rem',
    top: '-1rem',
    '@media screen and (max-width: 320px)': {
      right: '-1rem',
      top: '-1.15rem',
    },
    '@media screen and (min-width: 900px)': {
      fontSize: '4.2rem',
      right: '-2.1rem',
      top: '-2.1rem',
    },
  },
  bottomDot: {
    fontSize: '2.7rem',
    position: 'absolute',
    left: '15.2rem',
    bottom: '-1rem',
    '@media screen and (max-width: 320px)': {
      position: 'absolute',
      left: '13.7rem',
      bottom: '-0.85rem',
    },
    '@media screen and (min-width: 900px)': {
      fontSize: '4.2rem',
      left: '40.2rem',
      bottom: '-2.1rem',
    },
  },
  leftDotText: {
    position: 'absolute',
    left: '1.4rem',
    top: '17.5rem',
    transform: 'rotate(330deg)',
  },
  rightDotText: {
    position: 'absolute',
    right: '-6.5rem',
    top: '7.5rem',
    transform: 'rotate(90deg)',

  },
  bottomDotText: {
    position: 'absolute',
    right: '2rem',
    bottom: '4.3rem',
    transform: 'rotate(30deg)',
  },
  contentTitle: {
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    position: 'absolute',
    left: '0',
    right: '0',
    margin: '5rem 0 2rem 0',
    '@media screen and (min-width: 900px)': {
      width: '34rem',
      textAlign: 'right',
      left: '-105%',
      right: 'auto',
      margin: '23rem 0 2rem 0',
    },
  },
  contentText: {
    position: 'absolute',
    left: '0',
    right: '0',
    margin: '10rem 0 2rem 0',
    '@media screen and (min-width: 900px)': {
      maxWidth: '34rem',
      textAlign: 'right',
      left: '-105%',
      right: 'auto',
      margin: '28rem 0 2rem 0',
    },
  },
  beforeButton: {
    cursor: 'pointer',
    position: 'absolute',
    left: '0',
    bottom: '0',
    color: colors.gray,
    fontSize: '3rem',
    margin: '-0.7rem',
    '@media screen and (min-width: 900px)': {
      color: colors.black,
      left: '-103%',
      bottom: '3.5rem',
    },
  },
  nextButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: '0',
    bottom: '0',
    color: colors.gray,
    fontSize: '3rem',
    margin: '-0.7rem',
    '@media screen and (min-width: 900px)': {
      color: colors.black,
      left: '-28%',
      bottom: '3.5rem',
    },
  },
});

export default useStyles;
