import { makeStyles } from '@mui/styles';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  root: ({ backgroundImage }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'scroll',
    backgroundSize: 'cover',
    height: '100vh',
    '@media (min-width: 900px)': {
      backgroundPosition: 'center',
      transform: 'translateX(100%)',
    },
  }),
  container: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  logos: {
    color: colors.white,
    margin: '0 2rem 0 2rem',
    '@media (min-width: 900px)': {
      maxWidth: '35%',
      margin: '0 10rem 0 10rem',
    },
  },
  scrolledLogos: {
    position: 'fixed',
    top: '15rem',
    margin: '0 10rem 0 10rem',
    flexDirection: 'column',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  scrolledDividerContainer: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  scrolledDivider: {
    margin: '2rem 1rem 2rem 0',
    width: '100%',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  scrolledDot: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  logoPicture: {
    width: '20rem',
    height: '2rem',
    backgroundRepeat: 'no-repeat',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '@media (max-width: 320px)': {
      width: '11rem',
    },
    '@media (min-width: 375px)': {
      width: '14rem',
    },
    '@media (min-width: 768px)': {
      width: '17rem',
    },
    '@media (min-width: 900px)': {
      width: '13rem',
    },
    '@media (min-width: 1200px)': {
      width: '20rem',
    },
  },
  logoPictureLeft: {
    '& img': {
      objectPosition: 'left center',
    },
  },
  logoPictureRight: {
    '& img': {
      objectPosition: 'right center',
    },
  },
  scrolledLogoPicture: {
    width: '25rem',
    height: '2.5rem',
    backgroundRepeat: 'no-repeat',
    opacity: '0.3',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    '@media (min-width: 900px)': {
      width: '16rem',
    },
    '@media (min-width: 1200px)': {
      width: '25rem',
    },
  },
  divider: {
    color: colors.white,
    margin: '0 2rem',
    '@media (min-width: 900px)': {
      maxWidth: '35%',
      color: colors.lightGray,
      margin: '0 10rem 3rem 10rem',
    },
    '& svg': {
      fontSize: '2rem',
    },
    '&::before': {
      borderColor: colors.white,
      '@media (min-width: 900px)': {
        borderColor: colors.lightGray,
      },
    },
    '&::after': {
      borderColor: colors.white,
      '@media (min-width: 900px)': {
        borderColor: colors.lightGray,
      },
    },
    '@media (max-width: 320px)': {
      margin: '0 1rem',
    },
  },
  title: {
    margin: '0 2rem 2rem 2rem',
    '@media (max-width: 320px)': {
      fontSize: '3.2rem',
    },
    '@media (min-width: 900px)': {
      margin: '0 10rem 0 10rem',
      maxWidth: '35%',
      color: colors.black,
    },
  },
  description: {
    margin: '0 2rem 2rem 2rem',
    '@media (min-width: 900px)': {
      margin: '5rem 10rem 10rem 10rem',
      maxWidth: '35%',
    },
  },
  assessmentDivider: {
    borderColor: colors.lightGray,
    borderStyle: '0.1rem solid',
    margin: '9rem 2rem 0 2rem',
    '@media (min-width: 900px)': {
      display: 'none',
    },
  },
  assessmentsContainer: {
    marginBottom: '6rem',
    '@media screen and (min-width: 900px)': {
      marginBottom: '12.5rem',
    },
  },
});

export default useStyles;
