import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import colors from '../../../../helpers/colors';
import useStyles from './styles';
import contactFormSuccess from '../../../../img/contact-us-success-mobile.svg';
import unicornlyLogo from '../../../../img/logo_dark.png';
import useMobileView from '../../../../hooks/useMobileView';

const DialogConfirmation = ({ handleDialogState }) => {
  const classes = useStyles();
  const mobileView = useMobileView();

  return (
    <Grid container className={classes.root}>
      {!mobileView
            && (
            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between" className={classes.logoContainer}>
              <img alt="unicornly logo" src={unicornlyLogo} className={classes.logo} />
              <button
                type="button"
                className={classes.exitButton}
                onClick={handleDialogState}
              >
                <CloseIcon />
              </button>
            </Grid>
            )}
      <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between" className={classes.intro}>
        <Typography variant="h5" color={colors.black} align="left">
          Thank you!
        </Typography>
        {mobileView && (
        <button
          type="button"
          className={classes.exitButton}
          onClick={handleDialogState}
        >
          <CloseIcon />
        </button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color={colors.black} align="left" className={classes.description}>
          Thank you for your message,
          <br />
          we will reach you back within 24h.
        </Typography>
      </Grid>
      {mobileView
            && (
            <Grid item xs={12} display="flex" flexdirection="row" justifyContent="center" className={classes.successIcon}>
              <img alt="form send successfully" src={contactFormSuccess} />
            </Grid>
            )}
      <Grid item xs={12} className={classes.homepageRedirect}>
        <Link to="/" onClick={handleDialogState}>
          <Typography variant="h4" color={colors.violet} align="center">
            Go back to home page
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

DialogConfirmation.propTypes = {
  handleDialogState: PropTypes.func.isRequired,
};

export default DialogConfirmation;
