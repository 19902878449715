import { createReducer } from '@reduxjs/toolkit';
import initialMailStatus from './defaultState';
import { setMailResponseStatus } from './actions';

const sendMailReducer = createReducer(initialMailStatus, (builder) => {
  builder
    .addCase(setMailResponseStatus, (state, action) => {
      state.mailResponseStatus = action.payload;
    });
});

export default sendMailReducer;
