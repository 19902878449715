import { makeStyles } from '@mui/styles';
import contactUs from '../../../img/case-study-button.svg';

const useStyles = makeStyles({
  sectionTitle: {
    margin: '4rem 0 2rem 4rem',
    textTransform: 'capitalize',
    whiteSpace: 'pre-line',
    '@media (min-width: 900px)': {
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
      alignSelf: 'center',
      margin: '10rem 0 2rem -27vw',
    },
  },
  sectionDescription: {
    margin: '0 2rem 0 2rem ',
    whiteSpace: 'pre-line',
    '@media (min-width: 900px)': {
      alignSelf: 'center',
      maxWidth: '40%',
    },
  },
  contact: {
    margin: '4rem 0 4rem 0',
    '@media (min-width: 900px)': {
      margin: '10rem 0 4rem 0',
      fontSize: '3.6rem',
      lineHeight: '4.3rem',
    },
  },
  button: {
    width: '22.5rem',
    height: '5rem',
    padding: 0,
    backgroundImage: `url(${contactUs})`,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0',
  },
  buttonText: {
    fontWeight: '700',
    letterSpacing: '1px',
    textTransform: 'none',
    '@media (min-width: 900px)': {
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
    },
  },
  imageContainer: {
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    margin: '9rem 0 0 30vw',
    '& img': {
      width: '100%',
      height: '60rem',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
});

export default useStyles;
