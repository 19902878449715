import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  successLogo: {
    width: '27rem',
    height: '27rem',
    top: '50%',
    right: '25%',
    marginTop: '-13rem',
    marginRight: '-13rem',
    position: 'absolute',
  },
});

export default useStyles;
