import { makeStyles } from '@mui/styles';
import background from '../../../img/we_work_with_the_best_bg.png';

const useStyles = makeStyles({
  typography: {
    margin: '11.8rem 0 0 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '19.8rem 0 0 10rem',
    },
  },
  backgroundContainer: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: '45rem',
    '@media screen and (min-width: 900px)': {
      height: '79.1rem',
      backgroundSize: '79.1rem',
      transform: 'translateY(-18rem)',
    },
  },
});

export default useStyles;
