import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchTestimonials, setTestimonialsSuccess, setTestimonialsError } from './actions';
import getContent from '../../services/getContent';
import { TESTIMONIALS_URL } from '../../services/apiConfig';

function* fetchTestimonialsSaga() {
  try {
    const result = yield call(getContent, TESTIMONIALS_URL);
    yield put(setTestimonialsSuccess(result));
  } catch (error) {
    yield put(setTestimonialsError(`Testimonials - data fetching failed: ${error}`));
  }
}

export default function* watchFetchTestimonialsSaga() {
  yield takeEvery(String(fetchTestimonials), fetchTestimonialsSaga);
}
