import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import Divider from '@mui/material/Divider';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import useStyles from './styles';
import colors from '../../helpers/colors';
import { ReactComponent as LinkedInIcon } from '../../img/linkedin_icon.svg';
import { ReactComponent as FacebookIcon } from '../../img/facebook-icon.svg';
import { ReactComponent as ArticleIcon } from '../../img/article-icon.svg';
import ArticleContent from './ArticleContent';
import Footer from '../Footer';
import useMobileView from '../../hooks/useMobileView';
import RemainingArticles from './RemainingArticles';
import useScrolling from '../../hooks/useScrolling';
import globalVariables from '../../helpers/globalVariables';
import MetaData from '../MetaData';

const ArticlePage = ({
  fetchArticles,
  articles,
  fetchCurrentArticle,
  currentArticle,
}) => {
  const classes = useStyles({
    backgroundImage: currentArticle.image ? currentArticle.image.url : '',
  });
  const mobileView = useMobileView();
  const scroll = useScrolling(globalVariables.remainingArticlesHeight);
  const location = useLocation();
  const [copyConfirmation, setCopyConfirmation] = useState(false);
  const [visibleArticles, setVisibleArticles] = useState([]);
  const articleId = location.state;

  useEffect(() => {
    fetchCurrentArticle(articleId);
  }, [articleId]);

  useEffect(() => {
    if (articles.length === 0) {
      fetchArticles();
    }
    if (!articleId) {
      const urlArticleId = window.location.pathname.split('&id=').pop();
      fetchCurrentArticle({ id: urlArticleId });
    }
  }, []);

  useEffect(() => {
    setVisibleArticles(articles.filter(
      (article) => article.id !== currentArticle.id && article.is_visible,
    ).slice(0, 3));
  }, [articles, currentArticle]);

  const shareUrl = () => {
    if (window.location.pathname.includes(`&id=${currentArticle.id}`)) {
      return window.location.href;
    }
    return `${window.location.href}&id=${currentArticle.id}`;
  };

  const copyLinkHandler = () => {
    if (window.location.pathname.includes(`&id=${currentArticle.id}`)) {
      navigator.clipboard.writeText(window.location.href);
    } else {
      navigator.clipboard.writeText(`${window.location.href}&id=${currentArticle.id}`);
    }
    setCopyConfirmation(true);
  };

  const closeSnackbarHandler = () => {
    setCopyConfirmation(false);
  };

  const action = (
    <>
      <IconButton
        size="large"
        aria-label="close"
        onClick={closeSnackbarHandler}
      >
        <CloseIcon className={classes.closeSnackbarIcon} />
      </IconButton>
    </>
  );

  return (
    <>
      <MetaData
        title={`${currentArticle.meta_title} | ${currentArticle.category_name} | Unicornly`}
        metaDescription={`${currentArticle.meta_title} | ${currentArticle.category_name} | Unicornly`}
        shareUrl={shareUrl()}
        shareDialogTitle={`${currentArticle.meta_title} | ${currentArticle.category_name} | Unicornly`}
        shareDialogDescription={`${currentArticle.meta_title} | ${currentArticle.category_name} | Unicornly`}
        shareDialogImage={currentArticle.image ? currentArticle.image.url : ''}
      />
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid item>
            <Grid item md={6} className={classes.root} />
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className={!scroll ? classes.container : classes.scrolledContainer}
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.socialIcons}>
                <IconButton onClick={copyLinkHandler} className={classes.shareIconContainer}>
                  <SvgIcon
                    component={ArticleIcon}
                    fontSize="large"
                    viewBox="0 0 30 38"
                    titleAccess="article-icon"
                    className={classes.socialIcon}
                  />
                </IconButton>
                <Snackbar
                  className={classes.snackbar}
                  open={copyConfirmation}
                  onClose={closeSnackbarHandler}
                  autoHideDuration={6000}
                  action={action}
                  message="Link copied to clipboard"
                />
                <FacebookShareButton
                  url={shareUrl()}
                  className={classes.shareIconContainer}
                >
                  <SvgIcon
                    component={FacebookIcon}
                    fontSize="large"
                    viewBox="0 0 30 30"
                    titleAccess="facebook-icon"
                    className={classes.socialIcon}
                  />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={shareUrl()}
                  className={classes.shareIconContainer}
                >
                  <SvgIcon component={LinkedInIcon} fontSize="large" viewBox="0 0 30 30" titleAccess="linkedIn-icon" />
                </LinkedinShareButton>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" className={classes.articleDateRectangle}>
                  <Typography variant="body2" color={colors.violet} align="left" className={classes.articleDate}>
                    {currentArticle.date}
                  </Typography>
                </Box>
                <Typography variant="h3" color={colors.white} align="left" className={!scroll ? classes.title : classes.scrolledTitle}>
                  {currentArticle.title}
                </Typography>
                {!mobileView && !scroll
                && (
                <Typography variant="h4" color={colors.black} align="left" className={classes.intro}>
                  {currentArticle.intro}
                </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <ArticleContent currentArticle={currentArticle.article} />
          <Divider className={classes.divider} />
          <RemainingArticles visibleArticles={visibleArticles} />
          <Footer />
        </Grid>
      </Fade>
    </>
  );
};

ArticlePage.propTypes = {
  fetchCurrentArticle: PropTypes.func.isRequired,
  currentArticle: PropTypes.shape({
    article: PropTypes.arrayOf(PropTypes.shape),
    category_name: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    intro: PropTypes.string,
    is_visible: PropTypes.bool,
    meta_title: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  fetchArticles: PropTypes.func.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({
    article: PropTypes.arrayOf(PropTypes.shape),
    category_name: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    intro: PropTypes.string,
    is_visible: PropTypes.bool,
    meta_title: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
};

export default ArticlePage;
