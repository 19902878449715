import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

function TagManager({ acceptedCategories, tagManagerScript }) {
  return (
    <Helmet>
      {acceptedCategories.includes('analysis_marketing_cookies') && (
        <script id="tagmanager-script" key="tagmanager-script">
          {tagManagerScript}
        </script>
      )}
    </Helmet>
  );
}

TagManager.propTypes = {
  acceptedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagManagerScript: PropTypes.string.isRequired,
};

export default TagManager;
