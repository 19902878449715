import React from 'react';
import unicornlyLogo from '../../../../img/logo_light.png';
import useStyles from './styles';

const UnicornlyLogo = () => {
  const classes = useStyles();

  return (
    <img src={unicornlyLogo} alt="unicornly logo" className={classes.logo} />
  );
};

export default UnicornlyLogo;
