import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../helpers/colors';

const Intro = ({
  title,
  description,
  backgroundImage,
  backgroundPosition,
  titleStyle,
  descriptionStyle,
}) => {
  const classes = useStyles({
    backgroundImage,
    backgroundPosition,
  });

  return (
    <>
      <Grid item md={6} className={classes.introContainer} />
      <Box className={classes.container}>
        <Typography
          variant="h1"
          color={colors.white}
          align="left"
          className={titleStyle || classes.title}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          color={colors.white}
          align="left"
          className={descriptionStyle || classes.description}
        >
          {description}
        </Typography>
      </Box>
    </>
  );
};

Intro.defaultProps = {
  backgroundPosition: 'center',
  titleStyle: null,
  descriptionStyle: null,
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  backgroundPosition: PropTypes.string,
  titleStyle: PropTypes.string,
  descriptionStyle: PropTypes.string,
};

export default Intro;
