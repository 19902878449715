import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../helpers/colors';
import ContactDialog from '../../ContactDialog';
import useMobileView from '../../../hooks/useMobileView';

const Content = ({ caseStudy }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const mobileView = useMobileView();

  const handleDialogState = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  return (
    <>
      <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center" mt="10rem">
        <Typography variant="h5" colors={colors.black} align="left" className={classes.sectionTitle}>
          {caseStudy.paragraph_title_1}
        </Typography>
        <Typography variant="body2" color={colors.black} align="left" className={classes.sectionDescription}>
          {caseStudy.paragraph_1}
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h5" colors={colors.black} align="left" className={classes.sectionTitle}>
          {caseStudy.paragraph_title_2}
        </Typography>
        <Typography variant="body2" color={colors.black} align="left" className={classes.sectionDescription}>
          {caseStudy.paragraph_2}
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h5" color={colors.violet} align="center" className={classes.contact}>
          Do you have similar case?
          <br />
          Let’s talk today
        </Typography>
        <Button className={classes.button} onClick={handleDialogState}>
          <Typography variant="h5" color={colors.white} align="center" className={classes.buttonText}>
            Contact Us
          </Typography>
        </Button>
        <ContactDialog handleDialogState={handleDialogState} dialogState={isDialogOpen} />
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h5" colors={colors.black} align="left" className={classes.sectionTitle}>
          {caseStudy.paragraph_title_3}
        </Typography>
        <Typography variant="body2" color={colors.black} align="left" className={classes.sectionDescription}>
          {caseStudy.paragraph_3}
        </Typography>
      </Grid>
      {!mobileView && (
      <Grid item xs={12} className={classes.imageContainer}>
        <img alt="background" src={caseStudy.content_image ? caseStudy.content_image.url : ''} />
      </Grid>
      )}
      <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h5" colors={colors.black} align="left" className={classes.sectionTitle}>
          {caseStudy.paragraph_title_4}
        </Typography>
        <Typography variant="body2" color={colors.black} align="left" className={classes.sectionDescription}>
          {caseStudy.paragraph_4}
        </Typography>
      </Grid>
    </>
  );
};

Content.propTypes = {
  caseStudy: PropTypes.shape({
    id: PropTypes.number,
    paragraph_title_1: PropTypes.string,
    paragraph_1: PropTypes.string,
    paragraph_title_2: PropTypes.string,
    paragraph_2: PropTypes.string,
    paragraph_title_3: PropTypes.string,
    paragraph_3: PropTypes.string,
    paragraph_title_4: PropTypes.string,
    paragraph_4: PropTypes.string,
    content_image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};

export default Content;
