import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import colors from '../../../../helpers/colors';

function ReferenceCard({
  id,
  image,
  title,
  description,
  referenceLink,
}) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      <Box className={classes.card}>
        <Link to={referenceLink} state={{ id }} style={{ textDecoration: 'none' }}>
          <Box className={classes.imgContainer}>
            <img alt="case_study_img" src={image} />
          </Box>
          <Typography variant="body1" color={colors.black} align="left" className={classes.title}>
            {title}
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" color={colors.black} align="left" className={classes.description}>
            {description}
          </Typography>
          <Typography variant="h5" color={colors.violet} align="right" className={classes.readMore}>
            READ MORE
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

ReferenceCard.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  referenceLink: PropTypes.string.isRequired,
};

export default ReferenceCard;
