import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import RecentAssessments from './RecentAssessments';
import LetsTalkToday from '../HomePage/LetsTalkToday';
import Statistics from './Statistics';
import MetaData from '../MetaData';
import backgroundImage from '../../img/background_our_experience_desktop.png';
import Intro from '../Intro';
import pageContent from '../Intro/PagesIntroContent';
import useStyles from './styles';

function ExperiencePage({ fetchCaseStudies, caseStudies }) {
  const classes = useStyles();

  const [visibleCaseStudies, setVisibleCaseStudies] = useState([]);

  useEffect(() => {
    if (caseStudies.length === 0) {
      fetchCaseStudies();
    }
  }, []);

  useEffect(() => {
    setVisibleCaseStudies(caseStudies.filter(
      (caseStudy) => caseStudy.is_visible,
    ));
  }, [caseStudies]);

  return (
    <>
      <MetaData
        metaDescription="Multiple digital due diligence projects succesfully accomplished so far by Unicornly, additional descriptions of recent assemnents are shared as case studies."
        title="Examples of Digital Due Diligence | Unicornly"
      />
      <Fade in timeout={2000}>
        <Grid container flexDirection="column">
          <Grid item>
            <Intro
              title={pageContent.OurExperiencePage.pageTitle}
              description={pageContent.OurExperiencePage.description}
              backgroundImage={backgroundImage}
            />
          </Grid>
          <Grid item>
            <Statistics />
          </Grid>
          <Grid item className={classes.assessmentsContainer}>
            <RecentAssessments visibleCaseStudies={visibleCaseStudies} />
          </Grid>
          <Grid item>
            <LetsTalkToday />
          </Grid>
        </Grid>
      </Fade>
    </>

  );
}

ExperiencePage.propTypes = {
  fetchCaseStudies: PropTypes.func.isRequired,
  caseStudies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  })).isRequired,
};

export default ExperiencePage;
