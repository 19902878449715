import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  paragraph: {
    whiteSpace: 'pre-line',
    margin: '4rem 2rem 0 2rem',
    '@media (min-width: 900px)': {
      maxWidth: '50vw',
    },
  },
  title: {
    margin: '4rem 6rem 0 3rem',
    '@media (min-width: 900px)': {
      margin: '4rem 6rem 0 15rem',
      width: '50vw',
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
    },
  },
});

export default useStyles;
