import React from 'react';

const PagesIntroContent = {
  AboutUsPage: {
    id: 1,
    pageTitle: 'About Us',
    description: (
      <>
        <span>{'A combination of talent, skills and experience '}</span>
        makes us capable of delivering 360-degree due diligence with both business and
        technology in mind. We have been dealing with software and implementation of
        technological innovations in various market sectors for years.
      </>
    ),
  },
  WhatWeDoPage: {
    id: 2,
    pageTitle: 'What we do',
    description: (
      <>
        We help PEs, VCs and strategic buyers
        <span> reduce risk and increase value </span>
        by carrying out comprehensive analysis of
        the digital side of their future investments
      </>
    ),
  },
  OurExperiencePage: {
    id: 3,
    pageTitle: 'Our Experience',
    description: (
      <>
        We have a proven track record of delivering effective tech due diligence
        assessments for PE, VC and corporate investors.
        <span>
          {' '}
          Our experience is backed
          up by tangible case studies
          {' '}
        </span>
        in which our service added extra value by
        minimizing risks and increasing ROI.
      </>
    ),
  },
  BlogPage: {
    id: 4,
    pageTitle: 'Blog',
    description: (
      <>
        We have a proven track record of delivering effective tech due diligence assessments
        for PE, VC and corporate investors.
        <span>
          {' Our experience is backed up by tangible case studies '}
        </span>
        in which our service added extra value by minimizing risks and increasing ROI.
      </>
    ),
  },
  Homepage: {
    id: 5,
    pageTitle: '360-degree tech due diligence done by top-notch CTOs and tech entrepreneurs',
    description: (
      <>
        We help PEs, VCs and strategic buyers
        <span> reduce risk and increase value </span>
        by carrying out comprehensive analysis of
        the digital side of their future investments.
      </>
    ),
  },
};

export default PagesIntroContent;
