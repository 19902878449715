import colors from './colors';
import unicornlyLogoDark from '../img/logo_dark.png';
import unicornlyLogoLight from '../img/logo_light.png';

const navBarThemes = {
  dark: {
    divider: colors.darkBlue,
    button: colors.darkBlue,
    logo: unicornlyLogoDark,
  },
  light: {
    divider: colors.white,
    button: colors.white,
    logo: unicornlyLogoLight,
  },
};

export default navBarThemes;
