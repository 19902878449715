import { makeStyles } from '@mui/styles';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.darkBlue,
  },
  logo: {
    width: '14.1rem',
    height: '3.3rem',
    margin: '2rem',
    '@media screen and (min-width: 900px)': {
      width: '14.1rem',
      height: '3.3rem',
      margin: '6rem 0 2rem 10rem',
    },
  },
  intro: {
    marginLeft: '2rem',
    marginBottom: '6rem',
    '@media screen and (min-width: 900px)': {
      marginLeft: '10rem',
    },
  },
  container: {
    '@media screen and (min-width: 900px)': {
      flexDirection: 'row',
    },
  },
  linksContainer: {
    margin: '0 0 2rem 2rem',
    '@media screen and (min-width: 900px)': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0',
      paddingRight: '10rem',
      maxWidth: '55rem',
    },
  },
  link: {
    textDecoration: 'none',
  },
  linkText: {
    marginBottom: '2rem',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '@media screen and (min-width: 900px)': {
      marginTop: '7.1rem',
    },
  },
  divider: {
    borderColor: colors.white,
    borderStyle: '0.1rem, solid',
    margin: '0 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 10rem',
    },
  },
  footer: {
    '& a': {
      color: colors.white,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    textTransform: 'none',
    width: '25.8rem',
    margin: '3rem 2rem',
    '@media screen and (min-width: 900px)': {
      width: 'unset',
      margin: '6rem 10rem',
    },
  },
});

export default useStyles;
