import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import useStyles from './styles';
import colors from '../../../helpers/colors';

function Statistics({ fetchStatistics, statistics }) {
  const classes = useStyles();

  useEffect(() => {
    if (!Object.keys(statistics).length) {
      fetchStatistics();
    }
  }, []);

  return (
    <>
      <Typography variant="h2" colors={colors.black} align="left" className={classes.title}>
        UNICORNLY IN NUMBERS
      </Typography>
      <Grid container flexDirection="row" justifyContent="center">
        <Grid item xs={12} md={4} display="flex" flexDirection="row" justifyContent="center" className={classes.years}>
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.yearsContainer}>
            <Typography variant="h6" color={colors.violet} className={classes.valueText}>
              {statistics.years_in_business}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.yearsTextContainer}>
            <Typography variant="body1" color={colors.black} className={classes.descriptionText}>
              Years in business
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="center" className={classes.projects}>
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.projectsContainer}>
            <Typography variant="h6" color={colors.violet} className={classes.valueText}>
              {statistics.number_of_projects}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.projectsTextContainer}>
            <Typography variant="body1" color={colors.black} className={classes.descriptionText}>
              Number of projects
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="center" className={classes.deals}>
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.dealContainer}>
            <Typography variant="h6" color={colors.violet} className={classes.valueText}>
              ~$
              {statistics.deal_value}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.dealTextContainer}>
            <Typography variant="body1" color={colors.black} className={classes.descriptionText}>
              Deal value
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

Statistics.propTypes = {
  fetchStatistics: PropTypes.func.isRequired,
  statistics: PropTypes.shape({
    id: PropTypes.number,
    years_in_business: PropTypes.string,
    number_of_projects: PropTypes.string,
    deal_value: PropTypes.string,
  }).isRequired,
};

export default Statistics;
