import colors from '../../helpers/colors';
import navBarThemes from '../../helpers/navBarThemes';

const initialNavBar = {
  visibility: true,
  transparency: true,
  linkColor: colors.white,
  theme: navBarThemes.light,
};

export default initialNavBar;
