import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import colors from '../../../helpers/colors';
import useStyles from './styles';

function Testimonials({ fetchTestimonials, testimonials }) {
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (testimonials.length === 0) {
      fetchTestimonials();
    }
  }, []);

  const handlePrev = () => {
    if (tabIndex > 0) {
      setTabIndex((prevIndex) => prevIndex - 1);
    } else {
      setTabIndex(testimonials.length - 1);
    }
  };

  const handleNext = () => {
    if (tabIndex < testimonials.length - 1) {
      setTabIndex((prevIndex) => prevIndex + 1);
    } else {
      setTabIndex(0);
    }
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Typography
        className={classes.title}
        variant="h2"
        align="left"
        color={colors.black}
      >
        WHAT OUR CLIENTS SAY
      </Typography>
      <Box className={classes.container} display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.imgContainer}>
          <img alt="client" src={testimonials.length > 0 && testimonials[tabIndex].image !== null ? testimonials[tabIndex].image.url : ''} />
          <Box className={classes.buttonContainer} display="flex" flexDirection="row" justifyContent="space-between">
            <NavigateBeforeIcon onClick={handlePrev} />
            <NavigateNextIcon onClick={handleNext} />
          </Box>
        </Box>
        <Box className={classes.quoteContainer}>
          <Typography
            className={classes.quote}
            variant="body1"
            align="left"
            color={colors.black}
          >
            {testimonials.length > 0 && testimonials[tabIndex].content}
          </Typography>
          <Typography
            className={classes.name}
            variant="h5"
            align="left"
            color={colors.black}
          >
            {testimonials.length > 0 && testimonials[tabIndex].name}
          </Typography>
          <Typography
            className={classes.worksite}
            variant="body2"
            align="left"
            color={colors.black}
          >
            {testimonials.length > 0 && testimonials[tabIndex].position}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

Testimonials.propTypes = {
  fetchTestimonials: PropTypes.func.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  })).isRequired,
};

export default Testimonials;
