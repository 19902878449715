import { connect } from 'react-redux';
import BlogPage from './BlogPage';
import { getArticles, getErrorMessage } from '../../store/articles/selectors';
import { fetchArticles } from '../../store/articles/actions';

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchArticles: () => { dispatch(fetchArticles()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);
