import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import NavBar from './components/NavBar';
import HomePage from './components/HomePage';
import WhatWeDoPage from './components/WhatWeDoPage';
import ExperiencePage from './components/ExperiencePage';
import AboutPage from './components/AboutPage';
import BlogPage from './components/BlogPage';
import theme from './theme';
import ScrollToTop from './components/ScrollToTop';
import CaseStudies from './components/CaseStudies';
import ArticlePage from './components/ArticlePage';
import NotFoundPage from './components/NotFoundPage';
import CookieBanner from './components/CookieBanner';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop>
          <GlobalStyles styles={theme.global} />
          <CookieBanner>
            <Grid container direction="column" wrap="nowrap">
              <Grid item xs={12}>
                <NavBar />
              </Grid>
              <Grid item xs={12}>
                <Routes>
                  <Route path="" element={<HomePage />} />
                  <Route path="what-we-do" element={<WhatWeDoPage />} />
                  <Route path="experience" element={<ExperiencePage />} />
                  <Route path="about" element={<AboutPage />} />
                  <Route path="blog" element={<BlogPage />} />
                  <Route path="case-study/:slug" element={<CaseStudies />} />
                  <Route path="article/:slug" element={<ArticlePage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Grid>
            </Grid>
          </CookieBanner>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
