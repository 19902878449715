import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchClients, setClientsSuccess, setClientsError } from './actions';
import getContent from '../../services/getContent';
import { CLIENTS_URL } from '../../services/apiConfig';

function* fetchClientsSaga() {
  try {
    const result = yield call(getContent, CLIENTS_URL);
    yield put(setClientsSuccess(result));
  } catch (error) {
    yield put(setClientsError(`We Work With The Best - data fetching failed: ${error}`));
  }
}

export default function* watchFetchClientsSaga() {
  yield takeEvery(String(fetchClients), fetchClientsSaga);
}
