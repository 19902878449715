import React from 'react';
import PropTypes from 'prop-types';
import FormToFill from './FormToFill';
import DialogConfirmation from './DialogConfirmation';

const FormSide = ({ handleDialogState, submitted, setSubmitted }) => (
  <>
    {!submitted ? <FormToFill setSubmitted={setSubmitted} handleDialogState={handleDialogState} />
      : <DialogConfirmation handleDialogState={handleDialogState} />}
  </>
);

FormSide.propTypes = {
  handleDialogState: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  setSubmitted: PropTypes.func.isRequired,
};

export default FormSide;
