import { makeStyles } from '@mui/styles';
import rectangle from '../../../../img/what-we-do-rectangle-desktop.svg';

const useStyles = makeStyles(() => ({
  tabsContainer: {
    '@media (min-width: 900px)': {
      maxWidth: '86rem',
      margin: '0 auto 0 auto',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '130rem',
      margin: '0 auto 0 auto',
    },
  },
  rectangle: {
    backgroundImage: `url(${rectangle})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '40rem',
    padding: '0',
    margin: '1rem 0 1rem 0',
    '& img': {
      margin: '6rem 0 0 6rem',
    },
  },
  typographyTitle: {
    fontStyle: 'italic',
    textTransform: 'capitalize',
    margin: '6rem 0 0 3rem',
  },
  descriptionContainer: {
    padding: '0',
    margin: '6rem 3rem 2rem 4rem',
  },
  description: {
    fontStyle: 'italic',
    margin: '0 0 2rem 0',
    '& ul': {
      margin: '0',
      '& li': {
        margin: '0 1rem 1rem 0',
        maxWidth: '28rem',
      },
    },
  },
}));

export default useStyles;
