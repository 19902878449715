import { connect } from 'react-redux';
import Form from './Form';
import { sendMail } from '../../../../../store/mailService/actions';
import getMailResponseStatus from '../../../../../store/mailService/selectors';

const mapStateToProps = (state) => ({
  mailResponseStatus: getMailResponseStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendMail: (mailContent) => dispatch(sendMail(mailContent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
