import { createReducer } from '@reduxjs/toolkit';
import initialLeaderships from './defaultState';
import { setLeadershipsSuccess, setLeadershipsError } from './actions';

const leadershipsReducer = createReducer(initialLeaderships, (builder) => {
  builder
    .addCase(setLeadershipsSuccess, (state, action) => {
      state.leaderships = action.payload;
    })
    .addCase(setLeadershipsError, (state, action) => {
      state.errorMessage = action.payload;
    });
});

export default leadershipsReducer;
