import React from 'react';
import PropTypes from 'prop-types';
import UnicornlyLogo from './UnicornlyLogo';
import SuccessLogo from './SuccessLogo';

const LogoSide = ({ submitted }) => (
  <>
    {submitted ? <SuccessLogo /> : <UnicornlyLogo />}
  </>
);

LogoSide.propTypes = {
  submitted: PropTypes.bool.isRequired,
};

export default LogoSide;
