import { connect } from 'react-redux';
import CaseStudies from './CaseStudies';
import { getCaseStudies, getCaseStudy } from '../../store/caseStudies/selectors';
import { fetchCaseStudies, fetchCaseStudy } from '../../store/caseStudies/actions';

const mapStateToProps = (state) => ({
  currentCaseStudy: getCaseStudy(state),
  caseStudies: getCaseStudies(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentCaseStudy: (caseStudyId) => dispatch(fetchCaseStudy(caseStudyId)),
  fetchCaseStudies: () => dispatch(fetchCaseStudies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudies);
