import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import colors from '../../../helpers/colors';
import useStyles from './styles';

const steps = [
  {
    id: 1,
    description: 'From day 1, starting by understanding the investment or acquisition thesis, we keep the goals and expectations of stakeholders in mind.',
  },
  {
    id: 2,
    description: 'Then, we evaluate the technology, talent and processes against the desired outcomes.',
  },
  {
    id: 3,
    description: 'We assess 12 areas using a 360-degree approach, including architecture, codebase, infrastructure, security, technical team, processes, IP & open source, product strategy, user experience, customer acquisition, customer retention and market & competition.',
  },
  {
    id: 4,
    description: 'As a result, we identify risks that could potentially impact a transaction’s business objectives. We put a spotlight on adding extra value and highlighting opportunities that may increase ROI and accelerate growth. Thanks to this, PE, VC and corporate investors can make risk-proof decisions based on solid and comprehensive assessments.',
  },
];

const OurApproach = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" color={colors.black} align="left" className={classes.typography}>
            Our Approach
          </Typography>
          <Typography variant="h4" color={colors.black} align="left" className={classes.description}>
            Experience tells us that having the comfort of making decisions based on a
            solid understanding of the digital and business landscapes significantly
            increases the chances of the overall success of an investment.
            This is why Unicornly favors a holistic, A-Z approach to tech due diligence.
          </Typography>
          <Typography variant="h5" color={colors.violet} align="left" className={classes.typographyLarge}>
            360-degree tech due diligence done by top-notch CTOs and tech entrepreneurs
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.stepperContainer}>
          <Stepper className={classes.stepper} orientation="vertical">
            {steps.map((step) => (
              <Step active key={step.id}>
                <StepLabel />
                <StepContent className={classes.stepContentContainer}>
                  <Typography variant="body2" color={colors.darkGray} align="left" className={classes.stepDescription}>
                    {step.description}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </>
  );
};

export default OurApproach;
