import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import colors from '../../../helpers/colors';
import useStyles from './styles';
import useMobileView from '../../../hooks/useMobileView';
import Paragraph from './Paragraph';
import Quote from './Quote';

const ArticleContent = ({ currentArticle }) => {
  const classes = useStyles();
  const mobileView = useMobileView();

  return (
    <>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={classes.container}
      >
        {mobileView && (
        <Typography variant="body2" color={colors.black} align="left" className={classes.intro}>
          {currentArticle && currentArticle.intro}
        </Typography>
        )}
        {currentArticle && currentArticle.map((article) => {
          // eslint-disable-next-line no-underscore-dangle
          if (article.__component === 'paragraph.paragraph') {
            return (
              <Paragraph
                key={article.id + article.content}
                title={article.title}
                content={article.content}
              />
            );
          }
          // eslint-disable-next-line no-underscore-dangle
          if (article.__component === 'quote.quote') {
            return (
              <Quote
                key={article.id}
                quote={article.quote}
              />
            );
          }
          return null;
        })}
      </Grid>
    </>
  );
};

ArticleContent.defaultProps = {
  currentArticle: [],
};
ArticleContent.propTypes = {
  currentArticle: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    intro: PropTypes.string,
  })),
};

export default ArticleContent;
