import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: ({ background }) => ({
    background,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
  }),
}));

export default useStyles;
