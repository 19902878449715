import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {
    margin: '9.3rem 0 4rem 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '24.4rem 0 4rem 10rem',
    },
  },
  caseStudyContainer: {
    '@media screen and (min-width: 900px)': {
      maxWidth: '90rem',
    },
    '@media screen and (min-width: 1200px)': {
      maxWidth: '125rem',
    },
  },
});

export default useStyles;
