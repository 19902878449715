import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchArticles,
  fetchArticle,
  setArticlesSuccess,
  setArticlesError,
  setArticleSuccess,
} from './actions';
import getContent from '../../services/getContent';
import { ARTICLE_URL } from '../../services/apiConfig';

function* fetchArticlesSaga() {
  try {
    const result = yield call(getContent, ARTICLE_URL);
    yield put(setArticlesSuccess(result));
  } catch (error) {
    yield put(setArticlesError(`Articles - data fetching failed: ${error}`));
  }
}

function* fetchArticleSaga(action) {
  try {
    const result = yield call(getContent, `${ARTICLE_URL}/${action.payload.articleId.id}`);
    yield put(setArticleSuccess(result));
  } catch (error) {
    yield put(setArticlesError(`Articles - data fetching failed: ${error}`));
  }
}

export function* watchFetchArticlesSaga() {
  yield takeEvery(String(fetchArticles), fetchArticlesSaga);
}

export function* watchFetchArticleSaga() {
  yield takeEvery(String(fetchArticle), fetchArticleSaga);
}
