import { makeStyles } from '@mui/styles';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  cardContainer: {
    width: '33.5rem',
    '@media screen and (max-width: 320px)': {
      width: '31.5rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '40rem',
    },
  },
  image: {
    width: '33.5rem',
    height: '33.5rem',
    zIndex: '1',
    background: colors.darkGray,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 43% 100%, 37% 89%, 0 89%)',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      clipPath: 'polygon(0.2% 0.2%, 99.8% 0.2%, 99.8% 99.8%, 43.2% 99.8%, 37.2% 88.8%, 0.2% 88.8%)',
    },
    '@media screen and (max-width: 320px)': {
      width: '31.5rem',
      height: '31.5rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '40rem',
      height: '40rem',
    },
  },
  link: {
    cursor: 'pointer',
    position: 'absolute',
    left: '0',
    bottom: '-0.5rem',
    marginLeft: '2rem',
    '& img': {
      width: '3rem',
      height: '3rem',
    },
    '@media screen and (min-width: 900px)': {
      marginLeft: '6rem',
      bottom: '-0.8rem',
      '& img': {
        width: '3.7rem',
        height: '3.7rem',
      },
    },
  },
  name: {
    fontWeight: '700',
    margin: '2rem 4rem 0 4rem',
    '@media screen and (min-width: 900px)': {
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
    },
  },
  position: {
    fontWeight: '700',
    margin: '0 4rem 2rem 4rem',
  },
  description: {
    whiteSpace: 'pre-line',
    margin: '0 4rem 4rem 4rem',
  },
});

export default useStyles;
