import { connect } from 'react-redux';
import LeadershipSection from './LeadershipSection';
import { fetchLeaderships } from '../../../store/leaderships/actions';
import { getLeaderships } from '../../../store/leaderships/selectors';

const mapStateToProps = (state) => ({
  leaderships: getLeaderships(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchLeaderships: () => { dispatch(fetchLeaderships()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadershipSection);
