import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import useStyles from './styles';
import listIcon from '../../../img/what-we-do-list-icon.svg';
import colors from '../../../helpers/colors';

const DiligenceReport = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2" color={colors.black} align="left" className={classes.typography}>
          Diligence Report
        </Typography>
      </Grid>
      <Grid container className={classes.root} display="flex" flexDirection="row" justifyContent="center">
        <Grid className={classes.container} item xs={12} lg={3}>
          <Grid item>
            <img src={listIcon} alt="list icon" />
            <Typography variant="h5" color={colors.white} align="center" className={classes.typographyLarge}>
              Included in the tech due diligence report
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.checkContainer} item xs={12} lg={3}>
          <Grid item className={classes.titleContainer}>
            <Grid item>
              <LibraryAddCheckOutlinedIcon className={classes.checkIcon} />
            </Grid>
            <Grid item>
              <Typography variant="h5" color={colors.black} align="left" className={classes.titleTypography}>
                Executive Summary
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color={colors.black} align="left" className={classes.typographyDescription}>
            A clear, structured and concise overview of the most critical findings.
          </Typography>
        </Grid>
        <Grid className={classes.checkContainer} item xs={12} lg={3}>
          <Grid item className={classes.titleContainer}>
            <Grid item>
              <ListOutlinedIcon className={classes.checkIcon} />
            </Grid>
            <Grid item>
              <Typography variant="h5" color={colors.black} align="left" className={classes.titleTypography}>
                Detailed Report
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color={colors.black} align="left" className={classes.typographyDescription}>
            A list of observations, remarks, risks, opportunities and recommendations.
          </Typography>
        </Grid>
        <Grid className={classes.checkContainer} item xs={12} lg={3}>
          <Grid item className={classes.titleContainer}>
            <Grid item>
              <FormatListNumberedOutlinedIcon className={classes.checkIcon} />
            </Grid>
            <Grid item>
              <Typography variant="h5" color={colors.black} align="left" className={classes.titleTypography}>
                100-day Plan
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color={colors.black} align="left" className={classes.typographyDescription}>
            A clear timeline showing prioritization of tasks and suggestions for next steps.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DiligenceReport;
