import { makeStyles } from '@mui/styles';
import rectangle from '../../img/article-date-rectangle.svg';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  root: ({ backgroundImage }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh',
    '@media (min-width: 900px)': {
      backgroundPosition: 'center',
      transform: 'translateX(100%)',
      margin: '0 0 10rem 0',
    },
  }),
  container: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    '@media (min-width: 900px)': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  scrolledContainer: {
    position: 'fixed',
    top: '15rem',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: {
    margin: '0 3rem 3rem 2rem',
    '@media (min-width: 900px)': {
      color: colors.black,
      margin: '0 0 5rem 3.5rem',
      maxWidth: '50rem',
    },
  },
  scrolledTitle: {
    fontSize: '2.4rem',
    lineHeight: '2.5rem',
    color: colors.black,
    margin: '2rem 0 3rem 3.5rem',
    maxWidth: '20rem',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  intro: {
    margin: '0 5rem 0 3.5rem',
    maxWidth: '40%',
  },
  articleDateRectangle: {
    backgroundImage: `url(${rectangle})`,
    backgroundRepeat: 'no-repeat',
    height: '3.5rem',
    margin: '2rem 0rem 0rem 2rem',
    '@media (min-width: 900px)': {
      flexDirection: 'column',
      margin: '0 0 0 3.5rem',
    },
  },
  articleDate: {
    margin: '0.7rem 0 0 1rem',
  },
  shareIconContainer: {
    margin: '0',
    padding: '0',
  },
  socialIcons: {
    margin: '2rem 0 0 2rem',
    '@media (min-width: 900px)': {
      margin: '0 0 2rem 3.5rem',
    },
  },
  iconLink: {
    lineHeight: 0,
  },
  socialIcon: {
    margin: '0 0 2rem 0',
  },
  divider: {
    borderColor: colors.lightGray,
    margin: '9rem 2rem 0 2rem',
    borderWidth: '0.1rem',
    '@media (min-width: 900px)': {
      display: 'none',
    },
  },
  snackbar: {
    '& div': {
      fontSize: '2rem',
    },
  },
  closeSnackbarIcon: {
    color: colors.white,
    fontSize: '2rem',
  },
});

export default useStyles;
