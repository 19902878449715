import { takeEvery } from 'redux-saga/effects';
import {
  changeVisibility, changeTransparency, changeLinkColor, changeTheme,
} from './actions';

function* changeVisibilitySaga(action) {
  yield changeVisibility(action);
}

function* changeTransparencySaga(action) {
  yield changeTransparency(action);
}

function* changeLinkColorSaga(action) {
  yield changeLinkColor(action);
}

function* changeThemeSaga(action) {
  yield changeTheme(action);
}

export function* watchChangeVisibilitySaga() {
  yield takeEvery(String(changeVisibility), changeVisibilitySaga);
}

export function* watchChangeTransparencySaga() {
  yield takeEvery(String(changeTransparency), changeTransparencySaga);
}

export function* watchChangeLinkColorSaga() {
  yield takeEvery(String(changeLinkColor), changeLinkColorSaga);
}

export function* watchChangeThemeSaga() {
  yield takeEvery(String(changeTheme), changeThemeSaga);
}
