import { makeStyles } from '@mui/styles';
import colors from '../../../../helpers/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'none',
  },
  logo: {
    display: 'none',
  },
  title: {
    margin: '2rem 0 0 2rem',
    lineHeight: '4rem',
  },
  exitButton: {
    color: colors.black,
    border: 'none',
    background: 'transparent',
    padding: 0,
    margin: '2.5rem 2rem 0 0',
    cursor: 'pointer',
    '& svg': {
      fontSize: '3rem',
    },
  },
  descriptionText: {
    margin: '1rem 0 4rem 2rem',
    width: '29rem',
  },
  mandatoryText: {
    marginLeft: '2rem',
  },
  '@media screen and (min-width: 900px)': {
    root: {
      backgroundImage: `linear-gradient(to right, ${colors.white} 50%, ${colors.violet} 50%)`,
      position: 'relative',
    },
    logo: {
      width: '20.4rem',
      height: '4.8rem',
      display: 'block',
      position: 'absolute',
      top: '50%',
      right: '25%',
      marginTop: '-2.4rem',
      marginRight: '-10.2rem',
    },
    title: {
      margin: '6rem 0 0 10rem',
      width: '40%',
    },
    exitButton: {
      color: colors.white,
      margin: '7.5rem 10rem 0 0',
      '& svg': {
        fontSize: '4rem',
      },
    },
    descriptionText: {
      margin: '2rem 0 3.1rem 10rem',
      width: '35%',
    },
    mandatoryText: {
      marginLeft: '10rem',
    },
  },
});

export default useStyles;
