import { makeStyles } from '@mui/styles';
import background from '../../../img/testimonials_bg.svg';
import colors from '../../../helpers/colors';

const useStyles = makeStyles({
  root: {
    background: colors.whiteSmoke,
    margin: '6rem 0 12rem 0',
    '@media screen and (min-width: 900px)': {
      margin: '4.4rem 0 19rem 0',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(100% - 10rem) 13.5rem',
      backgroundSize: '58rem 58rem',
    },
  },
  title: {
    margin: '11.8rem 0 0 2rem',
    '@media screen and (min-width: 900px)': {
      margin: '19.8rem 0 0 10rem',
    },
  },
  container: {
    margin: '4rem 2rem',
    '@media screen and (min-width: 900px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
      margin: '5rem 10rem 0 10rem',
    },
  },
  imgContainer: {
    width: '30.5rem',
    height: '30.5rem',
    margin: 0,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
    },
    '@media screen and (min-width: 325px)': {
      width: '33.5rem',
      height: '33.5rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '30.9rem',
      height: '30.9rem',
    },
  },
  buttonContainer: {
    width: '30rem',
    margin: '1.5rem 0',
    cursor: 'pointer',
    '& svg': {
      color: colors.gray,
      margin: '-1rem',
      fontSize: '3rem',
    },
    '@media screen and (min-width: 325px)': {
      width: '33.5rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '60.5rem',
      marginTop: '8.5rem',
      '& svg': {
        color: colors.black,
      },
    },
  },
  quoteContainer: {
    height: '29.2rem',
    width: '31.5rem',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'none',
    marginTop: '4.5rem',
    '@media screen and (min-width: 325px)': {
      height: '29.2rem',
      width: '32.8rem',
    },
    '@media screen and (min-width: 900px)': {
      width: '100%',
      height: '30.9rem',
      margin: '0 0 18.1rem 0',
      backgroundImage: 'none',
    },
  },
  quote: {
    margin: '2.9rem 1.3rem 0 1.3rem',
    maxHeight: '19.5rem',
    overflow: 'auto',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 0 4.8rem',
      width: '42.5rem',
      height: '21.5rem',
      maxHeight: '21.5rem',
    },
  },
  name: {
    fontWeight: '700',
    margin: '2rem 1.3rem 0 1.3rem',
    '@media screen and (min-width: 900px)': {
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
      margin: '4.8rem 0 0 4.8rem',
    },
  },
  worksite: {
    fontWeight: '700',
    margin: '0 1.3rem',
    '@media screen and (min-width: 900px)': {
      margin: '0 0 0 4.8rem',
    },
  },
});

export default useStyles;
