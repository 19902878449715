import { createTheme } from '@mui/material/styles';
import colors from './helpers/colors';
import BlenderProWoff2 from './fonts/BlenderPro-Medium.woff2';

const theme = createTheme({
  global: {
    body: {
      margin: '0',
      backgroundColor: colors.white,
    },
  },
  typography: {
    fontFamily: 'BlenderPro',
    fontWeight: 'normal',
    h1: {
      fontSize: '7.2rem',
      lineHeight: '8.6rem',
    },
    h2: {
      fontSize: '1.6rem',
      lineHeight: '1.9rem',
      '@media screen and (min-width: 900px)': {
        fontSize: '2.4rem',
        lineHeight: '2.9rem',
      },
    },
    h3: {
      fontSize: '4.8rem',
      lineHeight: '5.8rem',
    },
    h4: {
      fontSize: '1.8rem',
      lineHeight: '2rem',
      '@media screen and (min-width: 900px)': {
        fontSize: '2.4rem',
        lineHeight: '4rem',
      },
    },
    h5: {
      fontSize: '2.4rem',
      lineHeight: '2.9rem',
      '@media screen and (min-width: 900px)': {
        fontSize: '4.8rem',
        lineHeight: '5.7rem',
      },
    },
    h6: {
      fontSize: '3.6rem',
      lineHeight: '4.3rem',
    },
    body1: {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
      '@media screen and (min-width: 900px)': {
        fontSize: '2.4rem',
        lineHeight: '2.9rem',
      },
    },
    body2: {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
    },
    subtitle1: {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      '@media screen and (min-width: 900px)': {
        fontSize: '1.6rem',
        lineHeight: '1.9rem',
      },
    },
    subtitle2: {
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BlenderPro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BlenderPro'), local('BlenderPro-Medium'), url(${BlenderProWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export default theme;
